<script generic="M extends Model<M>" lang="ts" setup>

import {computed, shallowReactive, shallowRef, watch} from "vue"
import {Id} from "@lib/common/model/Id"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {KontaktListController} from "@intranet/view/kontakt/KontaktListController"
import {FontSize} from "@lib/common/Enums"
import {Model} from "@lib/common/model/Model"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {KontaktViewModel} from "@intranet/view/kontakt/KontaktViewModel"
import {ArrangeableController} from "@lib/common/Arrangeable"
import {ValidateableList} from "@lib/common/validator/ValidateableListItem"
import {RowClassNameArgument} from "@lib/common/elementplus/types"
import {Resource} from "@lib/model/Resource"
import ValidateableInput from "@lib/view/validateable/ValidateableInput.vue"
import CrudColumn from "@lib/view/list/components/CrudColumn.vue"
import ArrangeableColumn from "@lib/view/list/ArrangeableColumn.vue"
import {KontaktAnrede} from "@generated/de/lohn24/model/kontakt/KontaktAnrede"
import {InputType} from "@lib/view/validateable/ValidateableProps"

export interface KontaktProps {
    dialogTitle?: string
    id: Id<M>
    relation: RepositoryRelation<M, Kontakt>
    controller: KontaktListController<M> | null
    path?: string
    size?: FontSize,
}

const emits = defineEmits<{
    "kontakte": [kontakte: Kontakt[]]
    "update:controller": [controller: KontaktListController<M> | null]
}>()

const props = withDefaults(
    defineProps<KontaktProps>(),
    {
        dialogTitle: "Kontakt",
        path: "kontakt",
        size: FontSize.small,
    },
)

const kontaktListController = shallowRef<KontaktListController<Organisation> | null>(null)
watch(() => props.id?.value, async (value, last) => {
    if (!value) {
        return
    }
    if (value && ((last && value != last) || props.controller?.modelId?.value !== value)) {
        kontaktListController.value = new KontaktListController(props.relation, new Id(value))
        const kontakte = (await (kontaktListController.value?.fetch() ?? [])).map(it => it.kontakt)
        emits("kontakte", kontakte)
        emits("update:controller", kontaktListController.value)
    } else if (props.controller) {
        kontaktListController.value = props.controller
    } else {
        kontaktListController.value = null
        emits("update:controller", kontaktListController.value)
        emits("kontakte", [])
    }
}, {immediate: true})

function onSave(row: KontaktViewModel) {
    row.bearbeitungszustand.unflagEdit()
}

const arrangeableController = computed(() => {
    const ctrl = kontaktListController.value
    return ctrl && new ArrangeableController<KontaktListController<any>, KontaktViewModel>(
        ctrl,
        (a, b) => {
            return ctrl.sort(a, b)
        },
    )
})

const validateableList = new ValidateableList<KontaktViewModel>(
    props.path,
    it => it.kontakt,
)

function clearValidate(row: KontaktViewModel) {
    validateableList.clear(row)
}

function validate(row: KontaktViewModel) {
    validateableList.validate(row)
}

function error(row: KontaktViewModel, name: string): string {
    return validateableList.error(row, name)
}

function prop(name: string, index: number): string {
    return validateableList.prop(name, index)
}

function rowClassName({row}: RowClassNameArgument<KontaktViewModel>) {
    const names: string[] = []
    if (row.bearbeitungszustand.deleted) {
        names.push("for-delete")
    }
    return names.join(" ")
}

const emailWarnings = shallowReactive({})

async function checkEmailWarnings(row: KontaktViewModel) {
    if (row.kontakt.email) {
        const kontakte = props.controller?.data ?? []
        if (kontakte.filter(it => it.kontakt.email === row.kontakt.email).length > 1) {
            kontakte.forEach(kontakt => {
                emailWarnings[kontakt.validationIndex] = "Diese Email wird mehrfach verwendet. Handelt es sich bei dieser um ein Postfach?"
            })
            return
        }
    } else {
        const benachrichtigungen = await Resource.kontakt.benachrichtigung.all(row.kontakt.id)
        if (benachrichtigungen.isNotEmpty())
            emailWarnings[row.validationIndex] = `Alle zugehörigen Benachrichtigungen (${benachrichtigungen.length}) werden gelöscht`
        return
    }
    delete emailWarnings[row.validationIndex]
}

watch(() => props.controller?.data, async (kontakte) => {
    if (kontakte?.isNotEmpty()) {
        await checkEmailWarnings(kontakte!.first())
    }
}, {immediate: true})
</script>

<template>
  <el-row :gutter="40" class="kontakt">
    <el-col v-if="kontaktListController" :span="24">
      <h3>{{ dialogTitle }}</h3>
      <slot name="pre-table"></slot>
      <el-table
              :data="kontaktListController.data"
              :empty-text="KontaktViewModel.label.emptyText"
              :row-class-name="rowClassName"
              stripe
      >
        <el-table-column
                :label="KontaktViewModel.label.anrede"
                :width="80"
                align="left"
        >
          <template #default="{ row }">
            <validateable-input
                    v-model="row.anrede"
                    :disabled="row.anredeDisabled"
                    :error="error(row, KontaktViewModel.properties.anrede)"
                    :size="size"
                    clearable
                    placeholder=" "
                    :type="InputType.select"
                    @validate="validate(row)"
            >
              <el-option :value="KontaktAnrede.HERR" label="Herr"></el-option>
              <el-option :value="KontaktAnrede.FRAU" label="Frau"></el-option>
            </validateable-input>
          </template>
        </el-table-column>
        <el-table-column
                :label="KontaktViewModel.label.titel"
                :prop="KontaktViewModel.properties.titel"
                :width="80"
                align="left"
        >
          <template #default="{ row }">
            <validateable-input
                    v-model="row.titel"
                    :disabled="row.titelDisabled"
                    :error="error(row, KontaktViewModel.properties.titel)"
                    :size="size"
                    @validate="validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
                :label="KontaktViewModel.label.vorname"
                align="left"
                prop="vorname"
        >
          <template #default="{ row }">
            <validateable-input
                    v-model="row.vorname"
                    :disabled="row.vornameDisabled"
                    :error="error(row, KontaktViewModel.properties.vorname)"
                    :size="size"
                    @validate="validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column :label="KontaktViewModel.label.name" align="left" prop="name">
          <template #default="{ row }">
            <validateable-input
                    v-model="row.name"
                    :disabled="row.nameDisabled"
                    :error="error(row, KontaktViewModel.properties.name)"
                    :size="size"
                    @validate="validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column :label="KontaktViewModel.label.telefon" prop="telefon">
          <template #default="{ row }">
            <validateable-input
                    v-model="row.telefon"
                    :disabled="row.telefonDisabled"
                    :error="error(row, KontaktViewModel.properties.telefon)"
                    :size="size"
                    @validate="validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column :label="KontaktViewModel.label.telefonMobil" prop="telefonMobil">
          <template #default="{ row }">
            <validateable-input
                    v-model="row.telefonMobil"
                    :disabled="row.telefonMobilDisabled"
                    :error="error(row, KontaktViewModel.properties.telefonMobil)"
                    :size="size"
                    @validate="validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
                :label="KontaktViewModel.label.email"
                :show-overflow-tooltip="false"
                prop="email"
        >
          <template #default="{ row }">
            <validateable-input
                    v-model="row.email"
                    :error="error(row, KontaktViewModel.properties.email)"
                    :size="size"
                    :warning="emailWarnings[row.validationIndex]"
                    @validate="checkEmailWarnings(row);validate(row)"
            />
          </template>
        </el-table-column>
        <el-table-column
                :label="KontaktViewModel.label.funktion"
                :show-overflow-tooltip="true"
                align="left"
        >
          <template #default="{ row }">
            <el-select v-model="row.funktion" :size="size" @change="validate(row)">
              <el-option
                      v-for="name in KontaktViewModel.labelFunktion.keys()"
                      :key="name"
                      :value="name"
              >
              </el-option>
            </el-select>

          </template>
        </el-table-column>
        <crud-column
                :controller="kontaktListController"
                createable
                deletable
                @delete="clearValidate($event)"
        />
        <arrangeable-column :controller="arrangeableController!" />
      </el-table>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.kontakt {

  .el-scrollbar__bar.is-horizontal {
    display: none !important;
  }

  .for-delete {
    background-color: var(--el-color-danger-light-7)
  }

  .ansprechpartner.underline {
    text-decoration: underline;
  }

  span.fix-line {
    position: absolute;
    bottom: 3px;
  }

  span.fix-line i {
    bottom: -3px;
  }

  .icon-copy:focus,
  .icon-copy:hover {
    color: var(--el-color-primary-light-3);
  }

  .icon-copy {
    color: var(--el-color-primary);
  }

  #kontakt_order {
    button.el-button {
      padding: 0;
      position: absolute;
      margin: 0

    }

    .move-up {
      top: 2px;
      left: 2px;
    }

    .move-down {
      bottom: 2px;
      left: 2px
    }
  }

}

</style>

<style></style>
