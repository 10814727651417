<script generic="L extends Model<L>, M extends Model<M>, C extends OptionalModelController<any>" lang="ts" setup>
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {Model} from "@lib/common/model/Model"
import {OptionalModelController} from "@lib/common/controller/OptionalModelController"
import {shallowRef, watch} from "vue"
import {Id} from "@lib/common/model/Id"

interface RelationViewProps<L extends Model<L> = any, R extends Model<R> = any> {
    parent: Id<L> | null
    relation: RepositoryRelation<L, R>
    controller: C | null
    buildControllerInstance: (model: R | null, relation: RepositoryRelation<L, R>) => C
}

const props = defineProps<RelationViewProps<L, M>>()
const emits = defineEmits<{
    "update:controller": [controller: C],
    "validate": [path: string]
}>()

function useRelation<L extends Model<L>, R extends Model<R>, C extends OptionalModelController<any>>(
    parent: Id<L> | null,
    relation: RepositoryRelation<L, R>,
    controller: C | null,
    buildControllerInstance: (model: R | null, relation: RepositoryRelation<L, R>) => C,
){

}

const localController = shallowRef<C>()
watch(() => props.parent, async value => {
    if (value && !props.controller) {
        const model = value ? await props.relation.firstOrNull(new Id(value)) : null
        localController.value = props.buildControllerInstance(model, props.relation)
        emits("update:controller", localController.value)
    } else if (props.controller) {
        localController.value = props.controller
    } else {
        localController.value = props.buildControllerInstance(null, props.relation)
        emits("update:controller", localController.value)
    }
}, {immediate: true})

</script>

<template>
  <slot
          v-if="localController"
          :key="parent"
          :model="localController!.model.value!"
          :controller="localController as C"
  />
</template>