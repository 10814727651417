<script lang="ts" setup>
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {NewsDialogController} from "@intranet/view/news/NewsDialogController"
import {computed, ref} from "vue"
import {NewsEditViewModel} from "@intranet/view/news/edit/NewsEditViewModel"
import {ValidationModel, Validator} from "@lib/common/validator/Validator"
import {DialogController} from "@lib/common/controller/DialogController"
import EditDialog from "@lib/view/editdialog/EditDialog.vue"
import {NewsType} from "@generated/de/lohn24/model/news/NewsType"
import {FontSize} from "@lib/common/Enums"
import NewsRichTextEditor from "@intranet/view/news/NewsRichTextEditor.vue"
import {NewsContentType} from "@generated/de/lohn24/model/news/NewsContentType"
import {News} from "@generated/de/lohn24/model/news/News"

const props = withDefaults(
    defineProps<ComponentRendererProps<News, NewsDialogController> & { path?: string, size?: FontSize }>(),
    {
        path: "news",
        __loader: ComponentRenderer.loaderDefault,
        onVanish: ComponentRenderer.onVanishDefault,
        size: FontSize.small,
    },
)

const news = computed(() => new NewsEditViewModel(props.controller.newsController.value.model.value.news))

const validator = new Validator(
    new ValidationModel(
        "news",
        props.path,
        () => news.value.news,
    ),
)

const activeTab = ref(props.controller.tabs.first().name)

async function onSave() {
    const {news} = await props.controller.newsController.value.update()
    await DialogController.closeDialogs()
    props.done && props.done(news)
}

function prop(name: string) {
    return `${props.path}.${name}`
}

function error(name: string) {
    return Validator.getErrorText(prop(name))
}

</script>

<template>
  <edit-dialog
          v-model="activeTab"
          :controller="props.controller"
          :validator="validator"
          label-width="0"
          no-tab-header
          @save="onSave()"
  >
    <template v-if="news.contentType === NewsContentType.HTML" #news-content>
      <news-rich-text-editor :news="news" :size="props.size" />
    </template>
    <template v-else-if="news.contentType === NewsContentType.MARKDOWN" #news-content>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item>
            <el-select v-model="news.type">
              <el-option :value="NewsType.Artikel" />
              <el-option :value="NewsType.Arbeitsanweisung" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">

        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item
                  :error="error(NewsEditViewModel.properties.title)"
                  :prop="prop(NewsEditViewModel.properties.title)"
          >
            <el-input v-model="news.title" placeholder="Title"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <h3>{{ news.title }}</h3>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="12" class="edit-box">
          <el-form-item
                  :error="error(NewsEditViewModel.properties.teaser)"
                  :prop="prop(NewsEditViewModel.properties.teaser)"
          >
            <el-input v-model="news.teaser" :rows="20" type="textarea" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="preview-box">
          <vue-showdown :markdown="news.teaser"></vue-showdown>
        </el-col>
        <el-col :span="12" class="preview-box">
          <vue-showdown>
            <template> ds</template>
          </vue-showdown>
        </el-col>
      </el-row>
    </template>
  </edit-dialog>
</template>

<style lang="scss">
.news-edit-dialog {
  .el-row + .el-row {
    margin-top: 20px;
  }

  .preview-box {
    text-align: left;
  }
}
</style>
