import {ViewModel} from "@lib/common/model/ViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {IAnschriftPerson} from "@intranet/view/anschrift/person/IAnschriftPerson"
import {IAnschriftPostfach} from "@intranet/view/anschrift/postfach/IAnschriftPostfach"
import {IAnschriftUnternehmen} from "@intranet/view/anschrift/unternehmen/IAnschriftUnternehmen"
import {AnschriftPersonViewModel} from "@intranet/view/anschrift/person/AnschriftPersonViewModel"
import {AnschriftUnternehmenViewModel} from "@intranet/view/anschrift/unternehmen/AnschriftUnternehmenViewModel"
import {AnschriftPostfachViewModel} from "@intranet/view/anschrift/postfach/AnschriftPostfachViewModel"

export class AnschriftViewModel extends ViewModel<AnschriftViewModel> {

    readonly anschrift: Anschrift

    constructor(anschrift: Anschrift) {
        super()
        this.anschrift = this.registerShallowReactive(anschrift)
    }

    static label = {
        art: "Art",
        [AnschriftArt.PERSON]: AnschriftPersonViewModel.label,
        [AnschriftArt.UNTERNEHMEN]: AnschriftUnternehmenViewModel.label,
        [AnschriftArt.POSTFACH]: AnschriftPostfachViewModel.label,
    }

    static errors = {
        [AnschriftArt.PERSON]: {

        }
    }

    get art(): AnschriftArt {
        return this.anschrift.art
    }

    set art(value: AnschriftArt) {
        this.anschrift.art = value
    }

    #anschriftPerson: IAnschriftPerson | null = null

    get [AnschriftArt.PERSON](): IAnschriftPerson {
        return this.#anschriftPerson = this.#anschriftPerson ?? new AnschriftPersonViewModel(this.anschrift)
    }

    #anschriftUnternehmen: IAnschriftUnternehmen | null = null

    get [AnschriftArt.UNTERNEHMEN](): IAnschriftUnternehmen {
        return this.#anschriftUnternehmen = this.#anschriftUnternehmen ?? new AnschriftUnternehmenViewModel(this.anschrift)
    }

    #anschriftPostfach: IAnschriftPostfach | null = null

    get [AnschriftArt.POSTFACH](): IAnschriftPostfach {
        return this.#anschriftPostfach = this.#anschriftPostfach ?? new AnschriftPostfachViewModel(this.anschrift)
    }
}

