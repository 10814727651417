import { IRuntimeTypeInformation, Id, Serializable } from "@lib/index"
import { MandantStatus } from "@generated/de/lohn24/model/mandant/MandantStatus"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"

export interface ISftpConfigurationListItem {
	mandantId: string
	status: MandantStatus
	kennung: string
	name: string
}

export class SftpConfigurationListItem extends Serializable<SftpConfigurationListItem> {
	public mandantId!: Id<Mandant>
	public status!: MandantStatus
	public kennung!: string
	public name!: string

	static _types: Record<keyof ISftpConfigurationListItem, IRuntimeTypeInformation> = {
		mandantId: { type: Id },
		status: { type: MandantStatus },
		kennung: { type: String },
		name: { type: String },
	}

	static props: Record<keyof ISftpConfigurationListItem, string> = {
		mandantId: "mandantId",
		status: "status",
		kennung: "kennung",
		name: "name",
	}

	constructor(_json: ISftpConfigurationListItem) {
		super(_json)
	}
}
