import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {OptionalAnschriftController} from "@intranet/view/anschrift/old/OptionalAnschriftController"
import {AnschriftViewModel} from "@intranet/view/anschrift/old/AnschriftViewModel"
import {computed} from "vue"
import {Model} from "@lib/common/model/Model"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {from} from "@lib/common/Functions"

export class AnschriftController<L extends Model<L>> extends OptionalAnschriftController<L> {

    static build<L extends Model<L>>(anschrift: Anschrift | null, relation: RepositoryRelation<L, Anschrift>) {
        return new AnschriftController(new AnschriftViewModel(anschrift ?? from(Anschrift, {})), relation)
    }

    model = computed<AnschriftViewModel>(() => this.editableValue.value)

    constructor(
        anschrift: AnschriftViewModel,
        public relation: RepositoryRelation<L, Anschrift>,
    ) {
        super(anschrift, relation, false)
    }

    protected override async createModel(model: AnschriftViewModel): Promise<AnschriftViewModel> {
        return new AnschriftViewModel(model.anschrift)
    }
}