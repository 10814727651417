import {Validator} from "@lib/common/validator/Validator"

export class ValidationProperties {
    constructor(
        public readonly prefix: string,
        public readonly properties: string[],
    ) {
    }

    errorSum() {
        return Validator.getErrorSum(this.prefix, ...this.properties)
    }
}
