<script lang="ts" setup>

import EditDialog from "@lib/view/editdialog/EditDialog.vue"
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {
    KooperationspartnerEditDialogController,
} from "@intranet/view/kooperationspartner/edit/KooperationspartnerEditDialogController"
import {ref} from "vue"
import {Resource} from "@lib/model/Resource"
import DebitorStammdaten from "@intranet/view/debitor/DebitorStammdaten.vue"
import Kontakt from "@intranet/view/kontakt/Kontakt.vue"
import KooperationspartnerBriefabsender
    from "@intranet/view/kooperationspartner/briefabsender/KooperationspartnerBriefabsender.vue"
import KooperationspartnerStammdaten
    from "@intranet/view/kooperationspartner/stammdaten/KooperationspartnerStammdaten.vue"
import Relation from "@lib/view/relation/Relation.vue"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {RelationDefaultTemplateContext} from "@lib/view/relation/RelationDefaultTemplateContext"
import {ValidationService} from "@lib/common/validator/ValidationService"

const props = withDefaults(
    defineProps<ComponentRendererProps<Kooperationspartner, KooperationspartnerEditDialogController>>(),
    {
        __loader: ComponentRenderer.loaderDefault,
        onVanish: ComponentRenderer.onVanishDefault,
    },
)

const activeTab = ref<string>(props.controller.tabs.first().name)
const validationService = new ValidationService(props.controller.validator)

</script>

<template>

  <edit-dialog
          v-model="activeTab"
          :controller="props.controller"
          :validator="props.controller.validator"
          @save="() => controller.save()"
  >
    <template #stammdaten-content>
      <kooperationspartner-stammdaten
              v-if="props.controller.viewModel.kooperationspartner"
              v-model:controller-anschrift="props.controller.anschriftController.value"
              :controller="props.controller.kooperationspartnerController.value"
              @validate="validationService.validate($event)"
      />
    </template>
    <template #debitor-content>
      <relation
              :build-controller-instance="DebitorController.build"
              v-model:controller="props.controller.debitorController.value"
              :parent="props.controller.viewModel.kooperationspartnerId"
              :relation="Resource.kooperationspartner.debitor"

      >
        <template #default="{controller}: RelationDefaultTemplateContext<DebitorController<Kooperationspartner>>">
          <debitor-stammdaten
                  :controller="controller"
                  :rechnungsanschrift="false"
                  :rechnungssteller="!props.controller.kooperationspartnerController.value.model.value.kooperationspartner.debitor"
                  postanschrift
                  @validate="validationService.validate($event)"

          />
        </template>
      </relation>
    </template>
    <template #kontakt-content>
      <kontakt
              :id="props.controller.viewModel.kooperationspartnerId"
              v-model:controller="props.controller.kontaktController.value"
              :relation="Resource.kooperationspartner.kontakt"
              class="p-b-10"
      />
    </template>
    <template #briefabsender-content>
      <kooperationspartner-briefabsender
              v-model:controller="props.controller.briefabsenderController.value"
              :kooperationspartner="props.controller.viewModel.kooperationspartner.id"
              @validate="validationService.validate($event)"
      />
    </template>
  </edit-dialog>
</template>
