<script setup lang="ts">
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {computed} from "vue"
import {KontaktSummaryViewModel} from "@intranet/view/kontakt/KontaktSummaryViewModel"
import {KontaktFunktion} from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import IconTelefon from "@lib/view/icons/IconTelefon.vue"
import IconAvatar from "@lib/view/icons/IconAvatar.vue"
import TelefonLink from "@lib/view/TelefonLink.vue"
import IconEmail from "@lib/view/icons/IconEmail.vue"
import {copyToClipboard} from "@lib/common/Functions"
import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import IconCopy from "@lib/view/icons/IconCopy.vue"

const props = withDefaults(
    defineProps<{
        dialogTitle?: string,
        data: Kontakt[] | { kontakt: Kontakt }[],
        headline?: boolean
    }>(),
    {
        dialogTitle: "Kontakt",
        headline: true,
    },
)

const viewModels = computed<KontaktSummaryViewModel[]>(() =>
    props.data
        .map(
            it => {
                if (it.kontakt instanceof Kontakt) {
                    return new KontaktSummaryViewModel(it.kontakt)
                } else {
                    return new KontaktSummaryViewModel(it)
                }
            },
        ),
)

const kontakte = viewModels.value
    .filter(it => it.kontakt.funktion !== KontaktFunktion.EMAILPOSTFACH)
    .sort((a, b) => b.kontakt.order - a.kontakt.order)

const postfaecher = viewModels.value
    .filter(it => it.kontakt.funktion === KontaktFunktion.EMAILPOSTFACH)
    .sort((a, b) => b.kontakt.order - a.kontakt.order)

const copyEmailOption = (email: string) => new ContextMenuItemOption(
    "E-Mail kopieren",
    () => copyToClipboard(email),
    IconCopy,
)

</script>

<template>
  <el-row class="kontakt-summary">
    <el-col v-if="viewModels.isNotEmpty()" :span="24">
      <el-row v-if="headline">
        <el-col :span="22">
          <h3>{{ dialogTitle }}</h3>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="1"></el-col>
      </el-row>
      <el-table
              v-if="postfaecher.isNotEmpty()"
              :data="postfaecher"
              size="small"
              stripe
      >
        <el-table-column>
          <template #header>
            <span><icon-email /></span>
            <span>{{ KontaktSummaryViewModel.label.postfach }}</span>
          </template>
          <template #default="{row}">
            <slot v-if="row.email"
                  :email="row.email"
                  :kontakt="row.kontakt"
                  name="email-prefix"
            />
            <span>{{ row.email }}</span>
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-table
              v-if="kontakte.isNotEmpty()"
              :data="kontakte"
              :empty-text="KontaktSummaryViewModel.label.emptyText"
              size="small"
              stripe
      >
        <el-table-column :show-overflow-tooltip="true" prop="name">
          <template #header>
            <span><icon-avatar /></span>
            <span>{{ KontaktSummaryViewModel.label.ansprechpartner }}</span>
          </template>
          <template #default="{ row }">
            <span :class="{ansprechpartner: true, underline: row.funktion === KontaktFunktion.HAUPTANSPRECHPARTNER,}">
              {{ row.ansprechpartner }}
            </span>
          </template>
        </el-table-column>
        <el-table-column :width="140">
          <template #header>
            <span>
              <icon-telefon />
            </span>
            <span>
              {{ KontaktSummaryViewModel.label.telefon }}
            </span>
          </template>
          <template #default="{ row }">
            <telefon-link :phone-number="row.telefon" />
          </template>
        </el-table-column>
        <el-table-column :width="140">
          <template #header>
            <span style="position: relative">
              <icon-telefon style="position: absolute; bottom: 2px" />
            </span>
            <span style="position: relative; left: 16px">
              {{ KontaktSummaryViewModel.label.mobil }}
            </span>
          </template>
          <template #default="{ row }">
            <telefon-link :phone-number="row.mobil" />
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true">
          <template #header>
            <span><icon-email /></span>
            <span>{{ KontaktSummaryViewModel.label.email }}</span>
          </template>
          <template #default="{ row }">
<!--            <tooltip-button :options="() => copyEmailOption(row.email)" />-->
            <slot v-if="row.email"
                  :email="row.email"
                  :kontakt="row.kontakt"
                  name="email-prefix"
            />
            <span>{{ row.email }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.kontakt-summary {
  .ansprechpartner.underline {
    text-decoration: underline;
  }
  .cell {
    button {
      display: inline-block;
    }

    span {
      position: relative;

      &:nth-child(2) {
        left: 16px;
      }

      i.el-icon {
        position: absolute;
        bottom: 1px;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

}
</style>