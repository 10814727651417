import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { IPostanschrift, Postanschrift } from "@generated/de/lohn24/model/brief/Postanschrift"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { DebitorStatus } from "@generated/de/lohn24/model/debitor/DebitorStatus"
import { DebitorSepa } from "@generated/de/lohn24/model/debitor/DebitorSepa"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BriefabsenderArt } from "@generated/de/lohn24/model/brief/BriefabsenderArt"
import { IBelegBuilder, BelegBuilder } from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import { BelegArt } from "@generated/de/lohn24/model/beleg/BelegArt"
import { AnschriftArt } from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IBeleg extends IModel {
	belegbearbeiter: string
	debitor: string
	kooperationspartner: string | null
	korrektur: string | null
	mandant: string | null
	rechnungssteller: string
	abweichendeBeleganschriftArt: AnschriftArt | null
	beleganschriftArt: AnschriftArt
	postanschriftArt: AnschriftArt | null
	belegart: BelegArt
	abweichendeBeleganschrift: boolean
	abweichendeBeleganschriftLandEu: boolean | null
	beleganschriftLandEu: boolean
	sammelrechnung: boolean
	sepa: boolean
	steuerschuldumkehr: boolean
	umsatzsteuerpflichtig: boolean
	rechnungsstellerBrieftyp: BriefabsenderArt
	belegBuilder: Array<IBelegBuilder> | null
	debitorSepa: DebitorSepa | null
	debitorStatus: DebitorStatus
	rechnungsstellerLogoSkalierung: number | null
	datevExportBeleg: LocalDateTime | null
	festgeschrieben: LocalDateTime
	datum: LocalDate
	debitorSepaGueltigkeit: LocalDate | null
	debitorSepaUnterschrift: LocalDate | null
	leistungsdatum: LocalDate | null
	abrechnungszeitraum: string | null
	leistungszeitraum: string
	summeNetto: number
	summeUmsatzsteuer: number
	postanschrift: IPostanschrift
	rechnungsanschrift: IPostanschrift
	abweichendeBeleganschriftAnrede: string | null
	abweichendeBeleganschriftLand: string | null
	abweichendeBeleganschriftLandIsoAlpha2: string | null
	abweichendeBeleganschriftName1: string | null
	abweichendeBeleganschriftName2: string | null
	abweichendeBeleganschriftOrt: string | null
	abweichendeBeleganschriftPostleitzahl: string | null
	abweichendeBeleganschriftStrasse: string | null
	abweichendeBeleganschriftTitel: string | null
	abweichendeBeleganschriftZusatz: string | null
	art: string
	beleganschriftAnrede: string | null
	beleganschriftLand: string
	beleganschriftLandIsoAlpha2: string
	beleganschriftName1: string
	beleganschriftName2: string | null
	beleganschriftOrt: string
	beleganschriftPostleitzahl: string
	beleganschriftStrasse: string
	beleganschriftTitel: string | null
	beleganschriftZeile1: string
	beleganschriftZeile10: string
	beleganschriftZeile11: string
	beleganschriftZeile2: string
	beleganschriftZeile3: string
	beleganschriftZeile4: string
	beleganschriftZeile5: string
	beleganschriftZeile6: string
	beleganschriftZeile7: string
	beleganschriftZeile8: string
	beleganschriftZeile9: string
	beleganschriftZusatz: string
	belegnummer: string
	belegnummerKorrektur: string | null
	belegtext: string
	debitorAbweichenderKontoinhaber: string | null
	debitorIban: string | null
	debitorKonto: string
	debitorKundenreferenz: string | null
	debitorMail: string | null
	debitorRechnungszusatz: string | null
	debitorSepaMandatsreferenz: string | null
	debitorUmsatzsteuerid: string | null
	postanschriftAnrede: string | null
	postanschriftLand: string | null
	postanschriftName1: string | null
	postanschriftName2: string | null
	postanschriftOrt: string | null
	postanschriftPostleitzahl: string | null
	postanschriftStrasse: string | null
	postanschriftTitel: string | null
	postanschriftZeile1: string
	postanschriftZeile10: string
	postanschriftZeile11: string
	postanschriftZeile2: string
	postanschriftZeile3: string
	postanschriftZeile4: string
	postanschriftZeile5: string
	postanschriftZeile6: string
	postanschriftZeile7: string
	postanschriftZeile8: string
	postanschriftZeile9: string
	postanschriftZusatz: string | null
	rechnungsstellerBic: string
	rechnungsstellerEmail: string
	rechnungsstellerGeschaeftsfuehrung: string
	rechnungsstellerGlaeubigerId: string | null
	rechnungsstellerIban: string
	rechnungsstellerInternet: string
	rechnungsstellerKurzname: string
	rechnungsstellerLogo: string | null
	rechnungsstellerName: string
	rechnungsstellerOrt: string
	rechnungsstellerPostleitzahl: string
	rechnungsstellerRegistergericht: string
	rechnungsstellerRegisternummer: string
	rechnungsstellerRuecksendeangabe: string
	rechnungsstellerStrasse: string
	rechnungsstellerTelefax: string
	rechnungsstellerTelefon: string
	rechnungsstellerUmsatzsteuerid: string
}

export class Beleg extends Model<Beleg> {
	public belegbearbeiter!: Id<Account>
	public debitor!: Id<Debitor>
	public kooperationspartner!: Id<Kooperationspartner> | null
	public korrektur!: Id<Beleg> | null
	public mandant!: Id<Mandant> | null
	public rechnungssteller!: Id<Firma>
	public abweichendeBeleganschriftArt!: AnschriftArt | null
	public beleganschriftArt!: AnschriftArt
	public postanschriftArt!: AnschriftArt | null
	public belegart!: BelegArt
	public abweichendeBeleganschrift!: boolean
	public abweichendeBeleganschriftLandEu!: boolean | null
	public beleganschriftLandEu!: boolean
	public sammelrechnung!: boolean
	public sepa!: boolean
	public steuerschuldumkehr!: boolean
	public umsatzsteuerpflichtig!: boolean
	public rechnungsstellerBrieftyp!: BriefabsenderArt
	public belegBuilder!: Array<BelegBuilder> | null
	public debitorSepa!: DebitorSepa | null
	public debitorStatus!: DebitorStatus
	public rechnungsstellerLogoSkalierung!: number | null
	public datevExportBeleg!: LocalDateTime | null
	public festgeschrieben!: LocalDateTime
	public datum!: LocalDate
	public debitorSepaGueltigkeit!: LocalDate | null
	public debitorSepaUnterschrift!: LocalDate | null
	public leistungsdatum!: LocalDate | null
	public abrechnungszeitraum!: LocalMonth | null
	public leistungszeitraum!: LocalMonth
	public summeNetto!: number
	public summeUmsatzsteuer!: number
	public postanschrift!: Postanschrift
	public rechnungsanschrift!: Postanschrift
	public abweichendeBeleganschriftAnrede!: string | null
	public abweichendeBeleganschriftLand!: string | null
	public abweichendeBeleganschriftLandIsoAlpha2!: string | null
	public abweichendeBeleganschriftName1!: string | null
	public abweichendeBeleganschriftName2!: string | null
	public abweichendeBeleganschriftOrt!: string | null
	public abweichendeBeleganschriftPostleitzahl!: string | null
	public abweichendeBeleganschriftStrasse!: string | null
	public abweichendeBeleganschriftTitel!: string | null
	public abweichendeBeleganschriftZusatz!: string | null
	public art!: string
	public beleganschriftAnrede!: string | null
	public beleganschriftLand!: string
	public beleganschriftLandIsoAlpha2!: string
	public beleganschriftName1!: string
	public beleganschriftName2!: string | null
	public beleganschriftOrt!: string
	public beleganschriftPostleitzahl!: string
	public beleganschriftStrasse!: string
	public beleganschriftTitel!: string | null
	public beleganschriftZeile1!: string
	public beleganschriftZeile10!: string
	public beleganschriftZeile11!: string
	public beleganschriftZeile2!: string
	public beleganschriftZeile3!: string
	public beleganschriftZeile4!: string
	public beleganschriftZeile5!: string
	public beleganschriftZeile6!: string
	public beleganschriftZeile7!: string
	public beleganschriftZeile8!: string
	public beleganschriftZeile9!: string
	public beleganschriftZusatz!: string
	public belegnummer!: string
	public belegnummerKorrektur!: string | null
	public belegtext!: string
	public debitorAbweichenderKontoinhaber!: string | null
	public debitorIban!: string | null
	public debitorKonto!: string
	public debitorKundenreferenz!: string | null
	public debitorMail!: string | null
	public debitorRechnungszusatz!: string | null
	public debitorSepaMandatsreferenz!: string | null
	public debitorUmsatzsteuerid!: string | null
	public postanschriftAnrede!: string | null
	public postanschriftLand!: string | null
	public postanschriftName1!: string | null
	public postanschriftName2!: string | null
	public postanschriftOrt!: string | null
	public postanschriftPostleitzahl!: string | null
	public postanschriftStrasse!: string | null
	public postanschriftTitel!: string | null
	public postanschriftZeile1!: string
	public postanschriftZeile10!: string
	public postanschriftZeile11!: string
	public postanschriftZeile2!: string
	public postanschriftZeile3!: string
	public postanschriftZeile4!: string
	public postanschriftZeile5!: string
	public postanschriftZeile6!: string
	public postanschriftZeile7!: string
	public postanschriftZeile8!: string
	public postanschriftZeile9!: string
	public postanschriftZusatz!: string | null
	public rechnungsstellerBic!: string
	public rechnungsstellerEmail!: string
	public rechnungsstellerGeschaeftsfuehrung!: string
	public rechnungsstellerGlaeubigerId!: string | null
	public rechnungsstellerIban!: string
	public rechnungsstellerInternet!: string
	public rechnungsstellerKurzname!: string
	public rechnungsstellerLogo!: string | null
	public rechnungsstellerName!: string
	public rechnungsstellerOrt!: string
	public rechnungsstellerPostleitzahl!: string
	public rechnungsstellerRegistergericht!: string
	public rechnungsstellerRegisternummer!: string
	public rechnungsstellerRuecksendeangabe!: string
	public rechnungsstellerStrasse!: string
	public rechnungsstellerTelefax!: string
	public rechnungsstellerTelefon!: string
	public rechnungsstellerUmsatzsteuerid!: string

	static _types: Record<keyof IBeleg, IRuntimeTypeInformation> = {
		id: { type: Id },
		belegbearbeiter: { type: Id },
		debitor: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		korrektur: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		rechnungssteller: { type: Id },
		abweichendeBeleganschriftArt: { type: AnschriftArt, nullable: true },
		beleganschriftArt: { type: AnschriftArt },
		postanschriftArt: { type: AnschriftArt, nullable: true },
		belegart: { type: BelegArt },
		abweichendeBeleganschrift: { type: Boolean },
		abweichendeBeleganschriftLandEu: { type: Boolean, nullable: true },
		beleganschriftLandEu: { type: Boolean },
		sammelrechnung: { type: Boolean },
		sepa: { type: Boolean },
		steuerschuldumkehr: { type: Boolean },
		umsatzsteuerpflichtig: { type: Boolean },
		rechnungsstellerBrieftyp: { type: BriefabsenderArt },
		belegBuilder: { type: [BelegBuilder], nullable: true },
		debitorSepa: { type: DebitorSepa, nullable: true },
		debitorStatus: { type: DebitorStatus },
		rechnungsstellerLogoSkalierung: { type: Number, nullable: true },
		datevExportBeleg: { type: LocalDateTime, nullable: true },
		festgeschrieben: { type: LocalDateTime },
		datum: { type: LocalDate },
		debitorSepaGueltigkeit: { type: LocalDate, nullable: true },
		debitorSepaUnterschrift: { type: LocalDate, nullable: true },
		leistungsdatum: { type: LocalDate, nullable: true },
		abrechnungszeitraum: { type: LocalMonth, nullable: true },
		leistungszeitraum: { type: LocalMonth },
		summeNetto: { type: Number },
		summeUmsatzsteuer: { type: Number },
		postanschrift: { type: Postanschrift },
		rechnungsanschrift: { type: Postanschrift },
		abweichendeBeleganschriftAnrede: { type: String, nullable: true },
		abweichendeBeleganschriftLand: { type: String, nullable: true },
		abweichendeBeleganschriftLandIsoAlpha2: { type: String, nullable: true },
		abweichendeBeleganschriftName1: { type: String, nullable: true },
		abweichendeBeleganschriftName2: { type: String, nullable: true },
		abweichendeBeleganschriftOrt: { type: String, nullable: true },
		abweichendeBeleganschriftPostleitzahl: { type: String, nullable: true },
		abweichendeBeleganschriftStrasse: { type: String, nullable: true },
		abweichendeBeleganschriftTitel: { type: String, nullable: true },
		abweichendeBeleganschriftZusatz: { type: String, nullable: true },
		art: { type: String },
		beleganschriftAnrede: { type: String, nullable: true },
		beleganschriftLand: { type: String },
		beleganschriftLandIsoAlpha2: { type: String },
		beleganschriftName1: { type: String },
		beleganschriftName2: { type: String, nullable: true },
		beleganschriftOrt: { type: String },
		beleganschriftPostleitzahl: { type: String },
		beleganschriftStrasse: { type: String },
		beleganschriftTitel: { type: String, nullable: true },
		beleganschriftZeile1: { type: String },
		beleganschriftZeile10: { type: String },
		beleganschriftZeile11: { type: String },
		beleganschriftZeile2: { type: String },
		beleganschriftZeile3: { type: String },
		beleganschriftZeile4: { type: String },
		beleganschriftZeile5: { type: String },
		beleganschriftZeile6: { type: String },
		beleganschriftZeile7: { type: String },
		beleganschriftZeile8: { type: String },
		beleganschriftZeile9: { type: String },
		beleganschriftZusatz: { type: String },
		belegnummer: { type: String },
		belegnummerKorrektur: { type: String, nullable: true },
		belegtext: { type: String },
		debitorAbweichenderKontoinhaber: { type: String, nullable: true },
		debitorIban: { type: String, nullable: true },
		debitorKonto: { type: String },
		debitorKundenreferenz: { type: String, nullable: true },
		debitorMail: { type: String, nullable: true },
		debitorRechnungszusatz: { type: String, nullable: true },
		debitorSepaMandatsreferenz: { type: String, nullable: true },
		debitorUmsatzsteuerid: { type: String, nullable: true },
		postanschriftAnrede: { type: String, nullable: true },
		postanschriftLand: { type: String, nullable: true },
		postanschriftName1: { type: String, nullable: true },
		postanschriftName2: { type: String, nullable: true },
		postanschriftOrt: { type: String, nullable: true },
		postanschriftPostleitzahl: { type: String, nullable: true },
		postanschriftStrasse: { type: String, nullable: true },
		postanschriftTitel: { type: String, nullable: true },
		postanschriftZeile1: { type: String },
		postanschriftZeile10: { type: String },
		postanschriftZeile11: { type: String },
		postanschriftZeile2: { type: String },
		postanschriftZeile3: { type: String },
		postanschriftZeile4: { type: String },
		postanschriftZeile5: { type: String },
		postanschriftZeile6: { type: String },
		postanschriftZeile7: { type: String },
		postanschriftZeile8: { type: String },
		postanschriftZeile9: { type: String },
		postanschriftZusatz: { type: String, nullable: true },
		rechnungsstellerBic: { type: String },
		rechnungsstellerEmail: { type: String },
		rechnungsstellerGeschaeftsfuehrung: { type: String },
		rechnungsstellerGlaeubigerId: { type: String, nullable: true },
		rechnungsstellerIban: { type: String },
		rechnungsstellerInternet: { type: String },
		rechnungsstellerKurzname: { type: String },
		rechnungsstellerLogo: { type: String, nullable: true },
		rechnungsstellerName: { type: String },
		rechnungsstellerOrt: { type: String },
		rechnungsstellerPostleitzahl: { type: String },
		rechnungsstellerRegistergericht: { type: String },
		rechnungsstellerRegisternummer: { type: String },
		rechnungsstellerRuecksendeangabe: { type: String },
		rechnungsstellerStrasse: { type: String },
		rechnungsstellerTelefax: { type: String },
		rechnungsstellerTelefon: { type: String },
		rechnungsstellerUmsatzsteuerid: { type: String },
	}

	static props: Record<keyof IBeleg, string> = {
		id: "id",
		belegbearbeiter: "belegbearbeiter",
		debitor: "debitor",
		kooperationspartner: "kooperationspartner",
		korrektur: "korrektur",
		mandant: "mandant",
		rechnungssteller: "rechnungssteller",
		abweichendeBeleganschriftArt: "abweichendeBeleganschriftArt",
		beleganschriftArt: "beleganschriftArt",
		postanschriftArt: "postanschriftArt",
		belegart: "belegart",
		abweichendeBeleganschrift: "abweichendeBeleganschrift",
		abweichendeBeleganschriftLandEu: "abweichendeBeleganschriftLandEu",
		beleganschriftLandEu: "beleganschriftLandEu",
		sammelrechnung: "sammelrechnung",
		sepa: "sepa",
		steuerschuldumkehr: "steuerschuldumkehr",
		umsatzsteuerpflichtig: "umsatzsteuerpflichtig",
		rechnungsstellerBrieftyp: "rechnungsstellerBrieftyp",
		belegBuilder: "belegBuilder",
		debitorSepa: "debitorSepa",
		debitorStatus: "debitorStatus",
		rechnungsstellerLogoSkalierung: "rechnungsstellerLogoSkalierung",
		datevExportBeleg: "datevExportBeleg",
		festgeschrieben: "festgeschrieben",
		datum: "datum",
		debitorSepaGueltigkeit: "debitorSepaGueltigkeit",
		debitorSepaUnterschrift: "debitorSepaUnterschrift",
		leistungsdatum: "leistungsdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
		leistungszeitraum: "leistungszeitraum",
		summeNetto: "summeNetto",
		summeUmsatzsteuer: "summeUmsatzsteuer",
		postanschrift: "postanschrift",
		rechnungsanschrift: "rechnungsanschrift",
		abweichendeBeleganschriftAnrede: "abweichendeBeleganschriftAnrede",
		abweichendeBeleganschriftLand: "abweichendeBeleganschriftLand",
		abweichendeBeleganschriftLandIsoAlpha2: "abweichendeBeleganschriftLandIsoAlpha2",
		abweichendeBeleganschriftName1: "abweichendeBeleganschriftName1",
		abweichendeBeleganschriftName2: "abweichendeBeleganschriftName2",
		abweichendeBeleganschriftOrt: "abweichendeBeleganschriftOrt",
		abweichendeBeleganschriftPostleitzahl: "abweichendeBeleganschriftPostleitzahl",
		abweichendeBeleganschriftStrasse: "abweichendeBeleganschriftStrasse",
		abweichendeBeleganschriftTitel: "abweichendeBeleganschriftTitel",
		abweichendeBeleganschriftZusatz: "abweichendeBeleganschriftZusatz",
		art: "art",
		beleganschriftAnrede: "beleganschriftAnrede",
		beleganschriftLand: "beleganschriftLand",
		beleganschriftLandIsoAlpha2: "beleganschriftLandIsoAlpha2",
		beleganschriftName1: "beleganschriftName1",
		beleganschriftName2: "beleganschriftName2",
		beleganschriftOrt: "beleganschriftOrt",
		beleganschriftPostleitzahl: "beleganschriftPostleitzahl",
		beleganschriftStrasse: "beleganschriftStrasse",
		beleganschriftTitel: "beleganschriftTitel",
		beleganschriftZeile1: "beleganschriftZeile1",
		beleganschriftZeile10: "beleganschriftZeile10",
		beleganschriftZeile11: "beleganschriftZeile11",
		beleganschriftZeile2: "beleganschriftZeile2",
		beleganschriftZeile3: "beleganschriftZeile3",
		beleganschriftZeile4: "beleganschriftZeile4",
		beleganschriftZeile5: "beleganschriftZeile5",
		beleganschriftZeile6: "beleganschriftZeile6",
		beleganschriftZeile7: "beleganschriftZeile7",
		beleganschriftZeile8: "beleganschriftZeile8",
		beleganschriftZeile9: "beleganschriftZeile9",
		beleganschriftZusatz: "beleganschriftZusatz",
		belegnummer: "belegnummer",
		belegnummerKorrektur: "belegnummerKorrektur",
		belegtext: "belegtext",
		debitorAbweichenderKontoinhaber: "debitorAbweichenderKontoinhaber",
		debitorIban: "debitorIban",
		debitorKonto: "debitorKonto",
		debitorKundenreferenz: "debitorKundenreferenz",
		debitorMail: "debitorMail",
		debitorRechnungszusatz: "debitorRechnungszusatz",
		debitorSepaMandatsreferenz: "debitorSepaMandatsreferenz",
		debitorUmsatzsteuerid: "debitorUmsatzsteuerid",
		postanschriftAnrede: "postanschriftAnrede",
		postanschriftLand: "postanschriftLand",
		postanschriftName1: "postanschriftName1",
		postanschriftName2: "postanschriftName2",
		postanschriftOrt: "postanschriftOrt",
		postanschriftPostleitzahl: "postanschriftPostleitzahl",
		postanschriftStrasse: "postanschriftStrasse",
		postanschriftTitel: "postanschriftTitel",
		postanschriftZeile1: "postanschriftZeile1",
		postanschriftZeile10: "postanschriftZeile10",
		postanschriftZeile11: "postanschriftZeile11",
		postanschriftZeile2: "postanschriftZeile2",
		postanschriftZeile3: "postanschriftZeile3",
		postanschriftZeile4: "postanschriftZeile4",
		postanschriftZeile5: "postanschriftZeile5",
		postanschriftZeile6: "postanschriftZeile6",
		postanschriftZeile7: "postanschriftZeile7",
		postanschriftZeile8: "postanschriftZeile8",
		postanschriftZeile9: "postanschriftZeile9",
		postanschriftZusatz: "postanschriftZusatz",
		rechnungsstellerBic: "rechnungsstellerBic",
		rechnungsstellerEmail: "rechnungsstellerEmail",
		rechnungsstellerGeschaeftsfuehrung: "rechnungsstellerGeschaeftsfuehrung",
		rechnungsstellerGlaeubigerId: "rechnungsstellerGlaeubigerId",
		rechnungsstellerIban: "rechnungsstellerIban",
		rechnungsstellerInternet: "rechnungsstellerInternet",
		rechnungsstellerKurzname: "rechnungsstellerKurzname",
		rechnungsstellerLogo: "rechnungsstellerLogo",
		rechnungsstellerName: "rechnungsstellerName",
		rechnungsstellerOrt: "rechnungsstellerOrt",
		rechnungsstellerPostleitzahl: "rechnungsstellerPostleitzahl",
		rechnungsstellerRegistergericht: "rechnungsstellerRegistergericht",
		rechnungsstellerRegisternummer: "rechnungsstellerRegisternummer",
		rechnungsstellerRuecksendeangabe: "rechnungsstellerRuecksendeangabe",
		rechnungsstellerStrasse: "rechnungsstellerStrasse",
		rechnungsstellerTelefax: "rechnungsstellerTelefax",
		rechnungsstellerTelefon: "rechnungsstellerTelefon",
		rechnungsstellerUmsatzsteuerid: "rechnungsstellerUmsatzsteuerid",
	}

	constructor(_json: IBeleg) {
		super(_json)
	}
}
