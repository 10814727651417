<script setup lang="ts">
import {Staat} from "@generated/de/lohn24/model/staat/Staat"
import {Id} from "@lib/common/model/Id"
import {Validateable, ValidateableInput} from "@lib/view"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Resource} from "@lib/model/Resource"
import {_props} from "@lib/common/_props"
import {Validator} from "@lib/common/validator/Validator"
import {LabelProperty} from "@lib/types"
import {IAnschriftPostfach} from "@intranet/view/anschrift/postfach/IAnschriftPostfach"
import {FontSize} from "@lib/common/Enums"
import {AnschriftPostfachViewModel} from "@intranet/view/anschrift/postfach/AnschriftPostfachViewModel"

const props = withDefaults(
    defineProps<
        IAnschriftPostfach
        & LabelProperty<IAnschriftPostfach>
        & { path: string }
        & { noMargin?: boolean, size?: FontSize }
    >(),
    {
        noMargin: false,
        size: FontSize.small,
    },
)

const emits = defineEmits<{
    "update:name": [name: string],
    "update:strasse": [strasse: string],
    "update:postleitzahl": [postleitzahl: string],
    "update:ort": [ort: string],
    "update:land": [land: Id<Staat>],
    "update:zusatz": [zusatz: string],
    "validate": [path?: string]
}>()

const errors = AnschriftPostfachViewModel.error(Validator.getErrorTextFactory(props.path))

</script>

<template>

  <validateable-input
          :model-value="props.name"
          :error="errors[_props<IAnschriftPostfach>().name]"
          :label="props.label[_props<IAnschriftPostfach>().name]"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits(`update:name`, $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.strasse"
          :error="errors[_props<IAnschriftPostfach>().strasse]"
          :label="props.label.strasse"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:strasse', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.postleitzahl"
          :error="errors[_props<IAnschriftPostfach>().postleitzahl]"
          :label="props.label[_props<IAnschriftPostfach>().postleitzahl]"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:postleitzahl', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.ort"
          :error="errors[_props<IAnschriftPostfach>().ort]"
          :label="props.label[_props<IAnschriftPostfach>().ort]"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:ort', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable
          :error="errors[_props<IAnschriftPostfach>().land]"
          :label="props.label[_props<IAnschriftPostfach>().land]"
          :size="props.size"
          :no-margin="props.noMargin"
  >
    <repository-options
            :model-value="props.land"
            @update:modelValue="emits('update:land', $event as Id<Staat>)"
            :repository="Resource.land"
            @change="emits('validate', path)"
    />
  </validateable>
  <validateable-input
          :model-value="props.zusatz"
          :error="errors[_props<IAnschriftPostfach>().zusatz]"
          :label="props.label[_props<IAnschriftPostfach>().zusatz]"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:zusatz', $event as string)"
          @validate="emits('validate', path)"
  />
</template>

<style scoped lang="scss">

</style>