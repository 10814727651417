import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"
import {MandantArtikelViewModel} from "@intranet/view/mandant/artikel/MandantArtikelViewModel"
import {ListController} from "@lib/common/controller/ListController"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {computed, shallowRef, ShallowRef} from "vue"
import {assertNotNull, from} from "@lib/common/Functions"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {ValidateableList} from "@lib/common/validator/ValidateableListItem"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"

export class MandantArtikelListController extends ListController<MandantArtikelViewModel> {
    override dataSaveFailedMessage = "Preise"
    override dataSavedSuccessfullyMessage = "Preise"
    mandant: Id<Mandant>
    public artikel: ShallowRef<Pair<ArtikelkatalogArtikel, Artikel>[]> = shallowRef([])
    artikelAvailable = computed<boolean>(() => {
        return this.artikel.value.length > this.data.length
    })
    artikelMap = computed<Record<number, Pair<ArtikelkatalogArtikel, Artikel>>>(() => {
            return this.artikel.value.reduce((acc, artikel) => {
                acc[artikel.first.id.value] = artikel
                return acc
            }, {})
        },
    )

    validateableList: ValidateableList<MandantArtikelViewModel> | null = null

    constructor(mandant: Id<Mandant>, public artikelkatalog: Id<Artikelkatalog>) {
        super()
        this.mandant = mandant
    }

    override get data() {
        return this.allValues.filter(item => {
            return !item.bearbeitungszustand.deleted
        })
    }

    override async create() {
        const viewModel = new MandantArtikelViewModel(null, null, from(MandantArtikel, {}))
        viewModel.validationIndex = ValidateableList.nextIndex(this.data)
        this.addRow(viewModel)
    }

    id(mandant?: Mandant): Id<Mandant> {
        return mandant?.id ?? this.mandant
    }

    override addRow(row: MandantArtikelViewModel) {
        row.mandantArtikel.mandant = this.mandant
        row.bearbeitungszustand.flagCreated().flagEdited()
        super.addRow(row)
    }

    protected async updateData(data: MandantArtikelViewModel[], mandant: Mandant): Promise<MandantArtikelViewModel[]> {
        const rtn: MandantArtikelViewModel[] = []
        const id = this.id(mandant)
        await Promise.all(data.mapNotNull(async it => {
            try {
                await this.validateableList?.validate(it)
                return null
            } catch (e) {
                if (e instanceof ValidationError)
                    return e
                else
                    throw e
            }
        }))

        const toDelete = data.filter(it => it.bearbeitungszustand.deleted)
        for (const model of toDelete)
            await this.resource.mandant.mandantartikel.delete(id, model.mandantArtikel)

        const toCreate = data.filter(it => it.bearbeitungszustand.created)
        for (const model of toCreate) {
            const mandantArtikel = await this.resource.mandant.mandantartikel.create(id, model.mandantArtikel)
            const viewModel = new MandantArtikelViewModel(model.artikelkatalogArtikel, model.artikel, mandantArtikel.first)
            viewModel.validationIndex = model.validationIndex
            rtn.push(viewModel)
        }

        const toUpdate = data.filter(it => !it.bearbeitungszustand.deleted && !it.bearbeitungszustand.created && it.changed)
        for (const model of toUpdate) {
            const mandantArtikel = await this.resource.mandant.mandantartikel.update(id, model.mandantArtikel)
            const viewModel = new MandantArtikelViewModel(model.artikelkatalogArtikel, model.artikel, mandantArtikel.first)
            viewModel.validationIndex = model.validationIndex
            rtn.push(viewModel)
        }

        return rtn
    }

    protected async fetchData(): Promise<MandantArtikelViewModel[]> {
        const id = this.mandant
        const artikel: Pair<ArtikelkatalogArtikel, Artikel>[] = this.artikel.value = await this.resource.artikelkatalog.artikel.all(this.artikelkatalog)
        const mandantArtikels = await this.resource.mandant.mandantartikel.all(id)
        return mandantArtikels
            .filter(it => it.second.artikelkatalog.value == this.artikelkatalog.value)
            .map(pair => {
                const {first: mandantArtikel, second: artikelkatalogArtikel} = pair
                return new MandantArtikelViewModel(
                    artikelkatalogArtikel,
                    assertNotNull(artikel.find(it => it.first.id.value === mandantArtikel.artikel.value)).second,
                    mandantArtikel,
                )
            })
    }
}