import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Staatsangehoerigkeit } from "@generated/de/lohn24/model/staatsangehoerigkeit/Staatsangehoerigkeit"
import { Kontinent } from "@generated/de/lohn24/model/konstanten/Kontinent"

export interface IStaat extends IModel {
	staatsangehoerigkeit: string
	eu: boolean
	bevCodeGebiet: number
	bevCodeNationalitaet: number
	bevCodeStaat: number
	kontinent: Kontinent
	isoAlpha2: string
	isoAlpha3: string
	name: string
	nameKurz: string
	nameLang: string
}

export class Staat extends Model<Staat> {
	public staatsangehoerigkeit!: Id<Staatsangehoerigkeit>
	public eu!: boolean
	public bevCodeGebiet!: number
	public bevCodeNationalitaet!: number
	public bevCodeStaat!: number
	public kontinent!: Kontinent
	public isoAlpha2!: string
	public isoAlpha3!: string
	public name!: string
	public nameKurz!: string
	public nameLang!: string

	static _types: Record<keyof IStaat, IRuntimeTypeInformation> = {
		id: { type: Id },
		staatsangehoerigkeit: { type: Id },
		eu: { type: Boolean },
		bevCodeGebiet: { type: Number },
		bevCodeNationalitaet: { type: Number },
		bevCodeStaat: { type: Number },
		kontinent: { type: Kontinent },
		isoAlpha2: { type: String },
		isoAlpha3: { type: String },
		name: { type: String },
		nameKurz: { type: String },
		nameLang: { type: String },
	}

	static props: Record<keyof IStaat, string> = {
		id: "id",
		staatsangehoerigkeit: "staatsangehoerigkeit",
		eu: "eu",
		bevCodeGebiet: "bevCodeGebiet",
		bevCodeNationalitaet: "bevCodeNationalitaet",
		bevCodeStaat: "bevCodeStaat",
		kontinent: "kontinent",
		isoAlpha2: "isoAlpha2",
		isoAlpha3: "isoAlpha3",
		name: "name",
		nameKurz: "nameKurz",
		nameLang: "nameLang",
	}

	constructor(_json: IStaat) {
		super(_json)
	}
}
