<script generic="M extends Model<M>" lang="ts" setup>

import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Validator} from "@lib/common/validator/Validator"
import {computed} from "vue"
import {AnschriftViewModel} from "@intranet/view/anschrift/AnschriftViewModel"
import {from} from "@lib/common/Functions"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {AnschriftController} from "@intranet/view/anschrift/old/AnschriftController"
import {Model} from "@lib/common/model/Model"
import {OptionalAnschriftController} from "@intranet/view/anschrift/old/OptionalAnschriftController"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {Resource} from "@lib/model/Resource"

const props = withDefaults(
    defineProps<{
        controller: AnschriftController<M> | OptionalAnschriftController<M>
        title?: string
        disabled?: boolean
        path: string
    }>(),
    {
        disabled: false,
        title: "Anschrift",
    },
)

const emits = defineEmits<{
    validate: []
}>()

const optional = computed(() =>
    props.controller instanceof OptionalAnschriftController && !(props.controller instanceof AnschriftController),
)

const anschrift = computed(
    () => props.controller.effectiveModel.value ?? new AnschriftViewModel(from(Anschrift, {})),
)

const anschriftArt = computed({
    get: () => props.controller.artProxy.value ?? "",
    set: value => {
        props.controller.artProxy.value = value
    },
})

function error(propName: string) {
    return Validator.getErrorText(prop(propName))
}

function prop(property: string) {
    return `${props.path}.${property}`
}


</script>

<template>
  <div v-if="(disabled && props.controller?.isNotEmpty) || !disabled">
    <h3 class="heading">
      <span>{{ props.title }}</span>
    </h3>
    <el-form-item label="Art">
      <el-select
              v-if="optional"
              v-model="props.controller.artProxy.value"
              :disabled="disabled"
              :placeholder="anschrift.placeholder.anschriftArt"
              clearable
              @change="emits('validate')"
      >
        <el-option :label="anschrift.label.unternehmen" :value="AnschriftArt.UNTERNEHMEN"></el-option>
        <el-option :label="anschrift.label.person" :value="AnschriftArt.PERSON"></el-option>
        <el-option :label="anschrift.label.postfach" :value="AnschriftArt.POSTFACH"></el-option>
      </el-select>
      <el-radio-group v-else v-model="anschrift.art" :disabled="disabled" name="art" @change="emits('validate')">
        <el-radio-button
                :label="AnschriftArt.UNTERNEHMEN"
                data-cy="anschrift-art-unternehmen"
        >
          {{ anschrift.label.unternehmen }}
        </el-radio-button>
        <el-radio-button
                :label="AnschriftArt.PERSON"
                data-cy="anschrift-art-person"
        >
          {{ anschrift.label.person }}
        </el-radio-button>
        <el-radio-button
                :label="AnschriftArt.POSTFACH"
                data-cy="anschrift-art-postfach"
        >
          {{ anschrift.label.postfach }}
        </el-radio-button>
      </el-radio-group>
    </el-form-item>
    <div v-if="!optional || anschriftArt !== ''">
      <div v-if="anschrift.art === 'PERSON'">
        <el-row :gutter="20">
          <el-col :span="13">
            <el-form-item
                    :error="error(Anschrift.props.anrede)"
                    :prop="prop(Anschrift.props.anrede)"
                    label="Anrede"
            >
              <el-input
                      v-model="anschrift.anrede"
                      :disabled="disabled"
                      data-cy="anschrift-anrede"
                      name="anrede"
              />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
                    :error="error(Anschrift.props.titel)"
                    :label="anschrift.label.titel"
                    :prop="prop(Anschrift.props.titel)"
                    label-width="85px"
            >
              <el-input
                      v-model="anschrift.titel"
                      :disabled="disabled"
                      data-cy="anschrift-title"
                      name="title"
              />
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item
                    :error="error(Anschrift.props.name2)"
                    :label="anschrift.label.vorname"
                    :prop="prop(Anschrift.props.name2)"
            >
              <el-input
                      v-model="anschrift.vorname"
                      :disabled="disabled"
                      data-cy="anschrift-vorname"
                      name="name2"
              />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
                    :error="error(Anschrift.props.name1)"
                    :label="anschrift.label.nachname"
                    :prop="prop(Anschrift.props.name1)"
                    label-width="85px"
            >
              <el-input
                      v-model="anschrift.nachname"
                      :disabled="disabled"
                      data-cy="anschrift-nachname"
                      name="name1"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-else-if="anschrift.art === AnschriftArt.POSTFACH">
        <el-form-item
                :error="error(Anschrift.props.name1)"
                :label="anschrift.label.name"
                :prop="prop(Anschrift.props.name1)"
        >
          <el-input
                  v-model="anschrift.postfachName"
                  :disabled="disabled"
                  data-cy="anschrift-postfachname"
                  name="name1"
          />
        </el-form-item>
      </div>
      <div v-else-if="anschrift.art === AnschriftArt.UNTERNEHMEN">
        <el-form-item
                :error="error(Anschrift.props.name1)"
                :label="anschrift.label.firma"
                :prop="prop(Anschrift.props.name1)"
        >
          <el-input
                  v-model="anschrift.unternehmenName"
                  :disabled="disabled"
                  data-cy="anschrift-unternehmenname"
                  name="name1"
          />
        </el-form-item>
      </div>
      <el-row :gutter="20">
        <el-col v-if="anschrift.art !== AnschriftArt.POSTFACH">
          <el-form-item
                  :error="error(Anschrift.props.strasse)"
                  :label="anschrift.label.strasse"
                  :prop="prop(Anschrift.props.strasse)"
          >
            <el-input
                    v-model="anschrift.strasse"
                    :disabled="disabled"
                    data-cy="anschrift-strasse"
                    name="strasse"
            />
          </el-form-item>
        </el-col>
        <el-col v-else>
          <el-form-item
                  :error="error(Anschrift.props.strasse)"
                  :label="anschrift.label.postfach"
                  :prop="prop(Anschrift.props.strasse)"
          >
            <el-input
                    v-model="anschrift.strasse"
                    :disabled="disabled"
                    data-cy="anschrift-postfach"
                    name="hausnummer"
            />
          </el-form-item>
        </el-col>
        <!--      postleitzahl-->
        <el-col>
          <el-form-item :error="error(Anschrift.props.postleitzahl)"
                        :label="anschrift.label.postleitzahl"
          >
            <el-input
                    v-model="anschrift.postleitzahl"
                    :disabled="disabled"
                    data-cy="anschrift-postleitzahl"
                    name="postleitzahl"
                    @change="emits('validate')"
            />
          </el-form-item>
        </el-col>
        <!--      ort-->
        <el-col>
          <el-form-item
                  :error="error(Anschrift.props.ort)"
                  :label="anschrift.label.ort"
                  :prop="prop(Anschrift.props.ort)"
          >
            <el-input
                    v-model="anschrift.ort"
                    :disabled="disabled"
                    data-cy="anschrift-ort"
                    name="ort"
                    @change="emits('validate')"
            />
          </el-form-item>
        </el-col>
        <!--      land-->
        <el-col>
          <el-form-item
                  :error="error(Anschrift.props.land)"
                  :label="anschrift.label.land"
                  :prop="prop(Anschrift.props.land)"
          >
            <repository-options
                    v-model="anschrift.land"
                    :disabled="disabled"
                    :repository="Resource.land"
                    data-cy="anschrift-land"
                    @change="emits('validate')"

            />
          </el-form-item>
        </el-col>
      </el-row>
      <!--    zusatz-->
      <el-form-item
              :error="error(anschrift.properties.zusatz)"
              :label="anschrift.label.zusatz"
              :prop="prop(anschrift.properties.zusatz)"
      >
        <el-input
                v-model="anschrift.zusatz"
                :disabled="disabled"
                data-cy="anschrift-zusatz"
                name="zusatz"
                @change="emits('validate')"
        />
      </el-form-item>
    </div>
  </div>
</template>

<style lang="scss">
h3.heading {
  justify-content: space-between;
}
</style>