import {AxiosRequestConfig} from "axios"
import {
    RepositoryDispatchMap,
    RepositoryEvent,
    RepositoryEventMap,
    RepositoryEventTarget,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"
import {RepositoryCache} from "@lib/common/repository/RepositoryCache"
import {ValidationResult} from "@lib/common/validator/ValidationResult"
import {Model} from "@lib/common/model/Model"
import Api from "@lib/common/axios/Api"
import RepositoryConfig, {repositoryConfig} from "@lib/common/repository/RepositoryConfig"
import {Id} from "@lib/common/model/Id"
import {Constructable, RecordOf} from "@lib/types"
import {from} from "@lib/common/Functions"
import {ModelBuilder} from "@lib/common/model/ModelBuilder"
import {BuilderRepository} from "@lib/common/repository/BuilderRepository"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {Validator} from "@lib/common/validator/Validator"
import {RepositoryHasManyWithRelation} from "@lib/common/repository/RepositoryHasManyWithRelation"
import {Serializable} from "@lib/common/serializable/Serializable"
import {ConfigurationModel} from "@lib/view/configuration/ConfiguationModel"
import {IRuntimeTypeInformation} from "@lib/common/model/IRuntimeTypeInformation"

export abstract class Repository<L extends Model<L>> {
    eventTarget = new RepositoryEventTarget<L>()
    cache!: RepositoryCache<Repository<L>, L>
    protected api: Api

    protected constructor(readonly type: Constructable<L>, readonly endpoint: string, config: RepositoryConfig = repositoryConfig) {
        this.cache = new RepositoryCache(this)
        this.api = Api.Instance(config.baseURL)
        this.addEventListener("create", () => this.cache.invalidate())
        this.addEventListener("update", (model) => this.cache.invalidateId(model.id.value))
    }

    async validate(entity: L, uniqueKey?: string): Promise<ValidationResult[]> {
        const error = await Validator.validate(this.endpoint, entity, uniqueKey)
        if (error) {
            const results = error.response!.data.map(result => from(ValidationResult, result))
            this.dispatchEvent("validate", results)
            return results
        }
        return Promise.resolve([] as ValidationResult[])
    }

    async get<K>(path: string, params?: K): Promise<L> {
        const result = this.deserialize(await this.api.get<RecordOf<L>>(this.route(path), params))
        this.dispatchEvent("get", result)
        return result
    }

    async find<K>(id: Id<L>, params?: K): Promise<L> {
        return this.deserialize(await this.api.get<RecordOf<L>>(this.route(`${id.value}`), params))
    }

    async firstOrNull<K>(id: Id<L>, params?: K): Promise<L | null> {
        const result = (await this.api.get<RecordOf<L> | null>(this.route(`${id.value}`), params))
        if (result)
            return this.deserialize(result)
        return result
    }

    async all<K>(params?: K): Promise<L[]> {
        return this.deserialize(await this.api.get<RecordOf<L>[]>(this.route(), params))
    }

    async allUnlimited<K>(params?: K): Promise<L[]> {
        return this.all(Object.assign(params || {}, {all: true}))
    }

    async delete(entity: L | Id<L>): Promise<void> {
        const id = entity instanceof this.type ? entity.id.value : (entity as Id<L>).value
        const path = this.route(`/${id}`)
        await this.api.delete<L>(path)
        this.dispatchEvent("delete", new Id(id))
        return this.cache.invalidateId(id)
    }

    async update(entity: L): Promise<L> {
        const result = await this.put(entity, `/${entity.id.value}`)
        await this.cache.update(result)
        this.dispatchEvent("update", result)
        return result
    }

    async insert(entity: L): Promise<L> {
        const result = await this.post(entity)
        await this.cache.update(result)
        this.dispatchEvent("create", result)
        return result
    }

    addEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<L>[E]) {
        return this.eventTarget.addEventListener.bind(this.eventTarget)(type, cb)
    }

    removeEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<L>[E]) {
        return this.eventTarget.removeEventListener.bind(this.eventTarget)(type, cb)
    }

    dispatchEvent<E extends RepostioryEvents>(type: E, data: RepositoryDispatchMap<L>[E]) {
        return this.eventTarget.dispatchEvent.bind(this.eventTarget)(new RepositoryEvent(type, data))
    }

    buildRelation<R extends Model<R>>(
        type: Constructable<R>,
        endpoint: string,
    ) {
        return new RepositoryRelation<L, R>(this.endpoint, type, endpoint, this.api)
    }

    buildConfiguration<R extends Serializable<R>>(
        configurationType: Constructable<R>,
        endpoint: string,
    ) {
        const type = class TypedConfiguationModel extends ConfigurationModel<R> {
            protected static _types: Partial<Record<keyof TypedConfiguationModel, IRuntimeTypeInformation>> = {
                id: {type: Id},
                configuration: {type: configurationType},
            }
        }

        return new RepositoryRelation<L, ConfigurationModel<R>>(this.endpoint, type, endpoint, this.api)
    }

    buildHasManyWithRelation<M extends Model<M>, R extends Model<R>>(
        typeMiddle: Constructable<M>,
        typeRight: Constructable<R>,
        endpoint: string,
    ) {
        return new RepositoryHasManyWithRelation(
            this.endpoint,
            typeMiddle,
            typeRight,
            endpoint,
        )
    }

    buildBuilder<B extends ModelBuilder<B>>(type: Constructable<B>, invalidateCache: boolean = true) {
        const builder = new BuilderRepository<B, L>(
            this.route(),
            type,
            this.type,
            this.api,
        )

        if (invalidateCache) {
            builder.addEventListener("finalize", () => this.cache.invalidate())
        }

        return builder
    }

    protected route(path: string | null = null): string {
        const result = path === null ? `/${this.endpoint}` : `/${this.endpoint}/${path}`
        return result.replace("//", "/")
    }

    protected deserialize(obj: RecordOf<L> | RecordOf<L>[]) {
        if (Array.isArray(obj)) {
            return obj.map(it => this.deserialize(it))
        }
        return new this.type(obj)
    }

    protected async post(model: L, path: string | null = null, config?: AxiosRequestConfig): Promise<L> {
        return this.deserialize(await this.api.post<Partial<L>>(this.route(path), model.toJSON(), config))
    }

    private async put(model: L, path: string | null = null, config?: AxiosRequestConfig): Promise<L> {
        return this.deserialize(await this.api.put<Partial<L>>(this.route(path), model.toJSON(), config))
    }
}
