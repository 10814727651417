import {IModel, Model} from "@lib/common/model/Model"
import {ValidationResult} from "@lib/common/validator/ValidationResult"
import {InternalRuleItem} from "async-validator"
import _ from "lodash"
import {computed, reactive, ref, Ref} from "vue"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {RepositoryEvent} from "@lib/common/repository/RepositoryEventTarget"
import Api from "@lib/common/axios/Api"
import {Config} from "@lib/Config"
import {Serializable} from "@lib/common/serializable/Serializable"

export type ValidateProps<I> = Record<keyof I, string | null | undefined> & {readonly path: string}

export class ValidationModel<M extends Serializable<M>> {
    constructor(public className: string, public uniqueName: string, public model: () => M | null, public skipValidate: () => boolean = () => false) {
    }
}

export class Validator {

    static validationstate: Ref<Record<string, string[]>> = ref({})
    private static eventTarget = new EventTarget()
    private static api: Api = Api.Instance(Config.API_BASE_URL)
    private models!: ValidationModel<any>[]
    rules = computed(() => {
        return this.models.reduce((acc, validationModel) => {
            acc[validationModel.uniqueName] = validationModel.model()
                && Validator.rules(validationModel.model(), this.createAsyncValidator(validationModel))
            return acc
        }, {} as Partial<Record<string, Model<any>>>)
    })
    ruleModel = computed<Record<string, Model<any>>>(() => {
        return this.models.reduce((acc, model) => {
            acc[model.uniqueName] = model.model()
            return acc
        }, {} as Record<string, Model<any>>)
    })

    constructor(...models: ValidationModel<any>[]) {
        this.models = models
    }

    static defaultRule(validator: (rule: InternalRuleItem) => void | Promise<void>, trigger: keyof GlobalEventHandlersEventMap = "blur") {
        return {
            trigger: trigger,
            asyncValidator: validator,
        }
    }

    static getErrorText(field: string) {
        return Validator.validationstate.value[field]?.join(", ") ?? ""
    }

    static getErrorTextFactory<I extends IModel>(path?: string): (property: Extract<keyof I, string>) => string | null | undefined {
        return (property: Extract<keyof I, string>) => Validator.getErrorText(`${path}.${property}`)
    }

    static gerErrorCount(prefix: string) {
        return Object.keys(Validator.validationstate.value)
            .filter(it => it.split(".").firstOrNull() === prefix)
            .length
    }

    static getErrorSum(prefix: string, ...properties: string[]): number {
        return properties.map(property => {
            const simple = `${prefix}.${property}`
            const indexed = new RegExp(`${prefix}\\.\\d+\\.${property}`)
            const validationState = Validator.validationstate.value
            const matches = Object.keys(validationState).filter(it => it === simple || it.match(indexed))
            return matches
                .map(it => {
                    return Validator.validationstate.value[it]?.length ?? 0
                })
                .sumBy(it => it)
        })
            .sumBy(it => it)
    }

    static rules<M extends Model<M>>(model: M, validator: (rule: InternalRuleItem) => void): InternalRuleItem {

        const result: InternalRuleItem = {}
        const keys = (model.props ?? []) as string[]
        for (const key of keys) {
            const item = model[key]
            if (typeof item === "object" && item !== null) {
                if (item instanceof Model) {
                    result[key] = Validator.rules(item, validator)
                } else {
                    // it is not of type Model
                    result[key] = this.defaultRule(validator)
                }
            } else {
                result[key] = this.defaultRule(validator)
            }
        }
        return result
    }

    static propNames(prefix: string, obj: Record<string, string>): string[] {
        return Object.values(obj).map(it => `${prefix}.${it}`)
    }

    static addEventListener(type: string, cb: (evt: ValidationResult[]) => void) {
        return Validator.eventTarget.addEventListener.bind(Validator.eventTarget)(type, (event: any) => {
            cb(event.detail)
        })
    }

    static removeEventListener(type: string, cb: (evt: ValidationResult[]) => void) {
        return Validator.eventTarget.removeEventListener.bind(Validator.eventTarget)(type, (event: any) => {
            cb(event.detail)
        })
    }

    static dispatchEvent(type: string, data: ValidationResult[]) {
        return Validator.eventTarget.dispatchEvent.bind(Validator.eventTarget)(new RepositoryEvent(type, data))
    }

    static async validate(path: string, model: Serializable<any>, uniqueName = path): Promise<ValidationError | null> {
        Validator.dispatchEvent(`before:validate:${path}`, [])
        try {
            Object.keys(Validator.validationstate.value).forEach(key => {
                if (key.startsWith(uniqueName)) {
                    delete Validator.validationstate.value[key]
                }
            })
            await Validator.api.post(Validator.route(path), model.toJSON())
            return null
        } catch (e) {
            if (e instanceof ValidationError) {
                const results = e.response?.data || []
                const customizedResults = results.map(it => {
                    it.propertyName = `${uniqueName}.${it.propertyName}`
                    Validator.validationstate.value[`${it.propertyName}`] = reactive([it.message])
                    return it
                })

                Validator.dispatchEvent("validate", customizedResults)
                Validator.dispatchEvent(path, customizedResults)
                return e
            } else {
                throw e
            }
        } finally {
            Validator.dispatchEvent(`after:validate:${path}`, [])
        }
    }

    static clearValidationState() {
        this.validationstate.value = {}
    }

    private static route(path: string) {
        return `/${path}/validate`.replace(/\/\//g, "/")
    }

    async validate(uniqueName?: string) {
        if (!uniqueName) {
            this.models.forEach(async validationModel => {
                try {
                    if (!validationModel.skipValidate()) {
                        const model = validationModel.model()
                        if (model instanceof Serializable) {
                            await Validator.validate(validationModel.className, model, validationModel.uniqueName)
                        }
                    }
                } catch (e) {
                    if (e instanceof ValidationError) {
                        //
                    } else {
                        throw e
                    }
                }
            })
        } else {
            const model = this.models.find(it => it.uniqueName === uniqueName)
            if (model && !model.skipValidate()) {
                try {

                    return await Validator.validate(model.className, model.model(), model.uniqueName)
                } catch (e) {
                    // new Logger(e).info(e)
                }
            }
        }
    }

    private createAsyncValidator(validationModel: ValidationModel<any>): (rule: InternalRuleItem) => Promise<void> {
        const debounced = _.debounce((model) => {
                return Validator.validate.bind(Validator)(validationModel.className, model, validationModel.uniqueName)
            },
            200, {
                leading: true,
                trailing: false,
            })
        const dispatch = Validator.dispatchEvent.bind(Validator)
        return async function (rule: InternalRuleItem) {
            try {
                if (validationModel.skipValidate()) {
                    Validator.dispatchEvent(`before:validate:${validationModel.uniqueName}`, [])
                    return Promise.resolve()
                }
                Object.keys(Validator.validationstate.value).forEach(key => {
                    if (key.split(".").slice(0, -1).join(".") === validationModel.uniqueName) {
                        delete Validator.validationstate.value[key]
                    }
                })
                await debounced(validationModel.model())
            } catch (e) {
                if (e instanceof ValidationError) {
                    const results = e.response?.data || []
                    if (e) {
                        Object.keys(Validator.validationstate.value).forEach(key => {
                            if (key.split(".")[0] === validationModel.uniqueName) {
                                //@ts-expect-error vue style to delete prop
                                Validator.validationstate.value[key] = undefined
                            }
                        })
                        let message
                        for (const result of results) {
                            if (`${validationModel.uniqueName}.${result.propertyName}` == rule.field || result.propertyName == rule.field) {
                                message = result.message
                                dispatch(`${validationModel.uniqueName}.${result.propertyName}`, [result])
                            }
                            Validator.validationstate.value[`${result.propertyName}`] = [result.message]
                        }
                        return Promise.reject(message)
                    }
                }
            }
        }
    }
}