import { IRuntimeTypeInformation, Id, LocalDate, LocalDateTime, Serializable } from "@lib/index"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { DebitorStatus } from "@generated/de/lohn24/model/debitor/DebitorStatus"
import { DebitorSepa } from "@generated/de/lohn24/model/debitor/DebitorSepa"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BereitstellungOptionRechnung } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"
import { IAnschrift, Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"

export interface IMandantDebitorBuilder {
	id: string
	rechnungssteller: string
	abweichendeRechnungsanschrift: IAnschrift | null
	postanschrift: IAnschrift | null
	sepaVomKundenAbgelehnt: boolean
	bereitstellungRechnung: Array<BereitstellungOptionRechnung>
	sepa: DebitorSepa | null
	status: DebitorStatus
	datevExportDebitor: LocalDateTime | null
	datevExportSepa: LocalDateTime | null
	sepaGueltigkeit: LocalDate | null
	sepaUnterschrift: LocalDate | null
	abweichenderKontoinhaber: string | null
	iban: string | null
	konto: string
	kundenreferenz: string | null
	mail: string | null
	name: string
	rechnungszusatz: string | null
	sepaMandatsreferenz: string | null
	umsatzsteuerid: string | null
}

export class MandantDebitorBuilder extends Serializable<MandantDebitorBuilder> {
	public id!: Id<Debitor>
	public rechnungssteller!: Id<Firma>
	public abweichendeRechnungsanschrift!: Anschrift | null
	public postanschrift!: Anschrift | null
	public sepaVomKundenAbgelehnt!: boolean
	public bereitstellungRechnung!: Array<BereitstellungOptionRechnung>
	public sepa!: DebitorSepa | null
	public status!: DebitorStatus
	public datevExportDebitor!: LocalDateTime | null
	public datevExportSepa!: LocalDateTime | null
	public sepaGueltigkeit!: LocalDate | null
	public sepaUnterschrift!: LocalDate | null
	public abweichenderKontoinhaber!: string | null
	public iban!: string | null
	public konto!: string
	public kundenreferenz!: string | null
	public mail!: string | null
	public name!: string
	public rechnungszusatz!: string | null
	public sepaMandatsreferenz!: string | null
	public umsatzsteuerid!: string | null

	static _types: Record<keyof IMandantDebitorBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		rechnungssteller: { type: Id },
		abweichendeRechnungsanschrift: { type: Anschrift, nullable: true },
		postanschrift: { type: Anschrift, nullable: true },
		sepaVomKundenAbgelehnt: { type: Boolean },
		bereitstellungRechnung: { type: [BereitstellungOptionRechnung] },
		sepa: { type: DebitorSepa, nullable: true },
		status: { type: DebitorStatus },
		datevExportDebitor: { type: LocalDateTime, nullable: true },
		datevExportSepa: { type: LocalDateTime, nullable: true },
		sepaGueltigkeit: { type: LocalDate, nullable: true },
		sepaUnterschrift: { type: LocalDate, nullable: true },
		abweichenderKontoinhaber: { type: String, nullable: true },
		iban: { type: String, nullable: true },
		konto: { type: String },
		kundenreferenz: { type: String, nullable: true },
		mail: { type: String, nullable: true },
		name: { type: String },
		rechnungszusatz: { type: String, nullable: true },
		sepaMandatsreferenz: { type: String, nullable: true },
		umsatzsteuerid: { type: String, nullable: true },
	}

	static props: Record<keyof IMandantDebitorBuilder, string> = {
		id: "id",
		rechnungssteller: "rechnungssteller",
		abweichendeRechnungsanschrift: "abweichendeRechnungsanschrift",
		postanschrift: "postanschrift",
		sepaVomKundenAbgelehnt: "sepaVomKundenAbgelehnt",
		bereitstellungRechnung: "bereitstellungRechnung",
		sepa: "sepa",
		status: "status",
		datevExportDebitor: "datevExportDebitor",
		datevExportSepa: "datevExportSepa",
		sepaGueltigkeit: "sepaGueltigkeit",
		sepaUnterschrift: "sepaUnterschrift",
		abweichenderKontoinhaber: "abweichenderKontoinhaber",
		iban: "iban",
		konto: "konto",
		kundenreferenz: "kundenreferenz",
		mail: "mail",
		name: "name",
		rechnungszusatz: "rechnungszusatz",
		sepaMandatsreferenz: "sepaMandatsreferenz",
		umsatzsteuerid: "umsatzsteuerid",
	}

	constructor(_json: IMandantDebitorBuilder) {
		super(_json)
	}
}
