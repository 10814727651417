import {ModelController} from "@lib/common/controller/ModelController"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"
import {assertNotNull, from} from "@lib/common/Functions"
import {Model} from "@lib/common/model/Model"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {Ref, watch, WatchStopHandle} from "vue"

export class DebitorController<L extends Model<L>> extends ModelController<DebitorViewModel> {

    dataSaveFailedMessage = "Debitor"
    dataSavedSuccesfullyMessage = "Debitor"

    constructor(model: DebitorViewModel, protected relation?: RepositoryRelation<L, Debitor>, rechnungsanschrift?: Anschrift, postanschrift?: Anschrift) {
        super(model)
    }

    override get modified(): boolean {
        return super.modified
    }

    get debitorId() {
        return this.model.value.debitor.id
    }

    static build<L extends Model<L>>(debitor: Debitor | null, relation: RepositoryRelation<L, Debitor>) {
        return new DebitorController(new DebitorViewModel(debitor ?? from(Debitor, {})), relation)
    }

    static synchronizeAnschriftWatcher<L extends Model<L>>(
        parent: Model<L> & { debitor: Id<Debitor> | null },
        relation: RepositoryRelation<L, Debitor>,
        debitorController: Ref<DebitorController<L> | null>,
        rechnungsanschrift: () => Anschrift | undefined,
    ): WatchStopHandle {
        const watchControllerHandle = watch(debitorController, (controller, controllerOld) => {
            if (!controllerOld) {
                const anschrift = rechnungsanschrift()
                if (!anschrift)
                    return
                Object.keys(Anschrift.props).forEach(key => {
                    assertNotNull(controller).model.value.debitor.rechnungsanschrift[key] = anschrift[key]
                })
            }
        })

        const watchAnschriftHandle = watch(rechnungsanschrift, async anschrift => {
            if (!anschrift)
                return

            let controller = debitorController.value
            if (!controller) {
                if (!parent.debitor)
                    return

                const debitor = await relation.first(parent.id)
                controller = debitorController.value = DebitorController.build(debitor, relation)
            }

            Object.keys(Anschrift.props).forEach(key => {
                assertNotNull(controller).model.value.debitor.rechnungsanschrift[key] = anschrift[key]
            })
        }, {deep: true})

        return () => {
            watchAnschriftHandle()
        }
    }

    async initialize(): Promise<void> {
    }

    override async update(id?: Id<any>, defaultValues?: { rechnungsanschrift?: Anschrift }): Promise<DebitorViewModel> {
        return super.update(id, defaultValues)
    }

    protected async createModel(): Promise<DebitorViewModel> {
        throw Error("use builder")
    }

    protected async updateModel(model: DebitorViewModel, id: Id<L> | null = null): Promise<DebitorViewModel> {
        if (this.relation) {
            const debitor = await this.relation.update(Id.required(id), model.debitor)
            return new DebitorViewModel(debitor)
        } else {
            const debitor = await Resource.debitor.update(model.debitor)
            return new DebitorViewModel(debitor)
        }
    }
}