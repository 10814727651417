<script lang="ts" setup>
import {computed} from "vue"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {AnschriftSummaryViewModel} from "@intranet/view/anschrift/old/AnschriftSummaryViewModel"

const props = withDefaults(
    defineProps<{
        anschrift: Anschrift | { anschrift: Anschrift },
        headline?: boolean
        horizontal?: boolean
    }>(),
    {
        headline: () => true,
    },
)

const viewModel = computed(() => {
    if (props.anschrift instanceof Anschrift) {
        return new AnschriftSummaryViewModel(props.anschrift)
    } else {
        return new AnschriftSummaryViewModel(props.anschrift.anschrift)
    }
})

</script>

<template>
  <el-row class="anschrift-summary">
    <el-col>
      <el-row v-if="headline" justify="space-between">
        <div class="el-col">
          <h3>{{ AnschriftSummaryViewModel.label.headline }}</h3>
        </div>
        <div class="el-col">
          <el-button-group class="anschrift-summary-button-group">
            <slot :btnClassName="'dialog-headline-btn'" name="header-right"></slot>
          </el-button-group>
        </div>
      </el-row>
      <el-table v-if="horizontal" :data="viewModel.dataHorizontal" fit size="small">
        <el-table-column
                :label="AnschriftSummaryViewModel.label.name"
                :prop="AnschriftSummaryViewModel.properties.name"
        />
        <el-table-column
                :label="AnschriftSummaryViewModel.label.strasse"
                :prop="AnschriftSummaryViewModel.properties.strasse"
        />
        <el-table-column
                :label="AnschriftSummaryViewModel.label.ort"
                :prop="AnschriftSummaryViewModel.properties.ort"
        />
      </el-table>
      <el-table v-else :data="viewModel.dataVertical" :show-header="false" fit size="small">
        <!-- <el-table-column prop="key" :width="120"> </el-table-column> -->
        <el-table-column :show-overflow-tooltip="true" prop="value"></el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.anschrift-summary {
  .anschrift-summary-button-group {
    .el-button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>