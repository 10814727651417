<script lang="ts" setup>
import {computed, ref} from "vue"
import Validateable from "@lib/view/validateable/Validateable.vue"
import {ValidateableInput} from "@lib/view"
import {FontSize} from "@lib/common/Enums"
import {UploadProps, UploadUserFile} from "element-plus"
import {Validator} from "@lib/common/validator/Validator"
import {uploadFileToBase64} from "@lib/common/Functions"
import {IBriefabsender} from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import {BriefabsenderArt} from "@generated/de/lohn24/model/brief/BriefabsenderArt"

interface BriefabsenderLabel {
    typ: string
    brieftyp: string
    name: string
    geschaeftsfuehrung: string
    strasse: string
    postleitzahl: string
    ort: string
    internet: string
    telefon: string
    telefax: string
    email: string
    registergericht: string
    registernummer: string
    umsatzsteuerid: string
    logo: string
    logoSkalierung: string
    ruecksendeangabe: string
    footer: string
}

interface BriefabsenderValidierungProperties {
    typ: string
    brieftyp: string
    name: string
    geschaeftsfuehrung: string
    strasse: string
    postleitzahl: string
    ort: string
    internet: string
    telefon: string
    telefax: string
    email: string
    registergericht: string
    registernummer: string
    umsatzsteuerid: string
    logo: string
    logoSkalierung: string
    ruecksendeangabe: string
    customFooter: string
}

interface BriefabsenderProps {
    model: IBriefabsender & { briefabsenderArt?: BriefabsenderArt | null }
    path: string
    size?: FontSize
    label?: BriefabsenderLabel
    error?: BriefabsenderValidierungProperties
}

const emits = defineEmits<{
    "validate": [path: string]
}>()

const props = withDefaults(
    defineProps<BriefabsenderProps>(),
    {
        size: () => FontSize.small,
        label: () => ({
            typ: "Art",
            brieftyp: "Art",
            name: "Name",
            geschaeftsfuehrung: "Geschaeftsführung",
            strasse: "Straße",
            postleitzahl: "Postleitzahl",
            ort: "Ort",
            internet: "Internet",
            telefon: "Telefon",
            telefax: "Fax",
            umsatzsteuerid: "USt-ID",
            registergericht: "Registergericht",
            registernummer: "Registernummer",
            email: "E-Mail",
            logo: "Logo",
            logoSkalierung: "Skalierungsfaktor",
            ruecksendeangabe: "Ruecksendeangabe",
            footer: "Fußzeile",
        }),
        error: () => ({
            typ: "typ",
            brieftyp: "brieftyp",
            name: "name",
            geschaeftsfuehrung: "geschaeftsfuehrung",
            strasse: "strasse",
            postleitzahl: "postleitzahl",
            ort: "ort",
            internet: "internet",
            telefon: "telefon",
            telefax: "telefax",
            email: "email",
            registergericht: "registergericht",
            registernummer: "registernummer",
            umsatzsteuerid: "umsatzsteuerid",
            logo: "logo",
            logoSkalierung: "logoSkalierung",
            ruecksendeangabe: "ruecksendeangabe",
            customFooter: "customFooter",
        }),
    },
)
const isOptional = computed(() => props.model.briefabsenderArt !== undefined)
const hasType = computed(() => !isOptional.value || props.model.briefabsenderArt != null)
const fileList = ref<UploadUserFile[]>([])
const onFileChange: UploadProps["onChange"] = async (uploadFile) => {
    if (uploadFile.raw) {
        fileList.value = [uploadFile]
        props.model.logo = await uploadFileToBase64(uploadFile)
        const img = new Image()
        img.onload = () => {
            props.model.logoSkalierung = +Math.min((200 / img.width), (40 / img.height)).toFixed(2)
        }
        img.src = props.model.logo

    } else {
        fileList.value = []
        props.model.logo = null
        props.model.logoSkalierung = null
    }
    emits("validate", props.path)
}

function errorText(prop: string): string {
    return Validator.getErrorText(`${props.path}.${prop}`)
}

function footerRowCount(): number {
    if (props.model.fusszeile === null || props.model.fusszeile.isEmpty()) {
        return 0
    } else {
        return props.model.fusszeile.split("\n").length
    }
}

</script>

<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <h3>Briefabsender</h3>
    </el-col>
    <el-col :span="12">
    </el-col>
    <el-col :span="12">
      <validateable
              v-if="isOptional"
              :error="errorText(props.error.brieftyp)"
              :label="props.label.brieftyp"
              :prop="props.error.brieftyp"
              :size="props.size"
              @validate="emits('validate', path)"
      >
        <el-select
                v-model="props.model.briefabsenderArt"
                clearable
                @change="emits('validate', props.path)"
        >
          <el-option label="Person" :value="BriefabsenderArt.PERSON"></el-option>
          <el-option label="Unternehmen" :value="BriefabsenderArt.UNTERNEHMEN"></el-option>
        </el-select>
      </validateable>
      <validateable
              v-else
              :error="errorText(props.error.typ)"
              :label="props.label.typ"
              :prop="props.error.typ"
              :size="props.size"
      >
        <el-select v-model="props.model.briefabsenderArt" @change="emits('validate', path)">
          <el-option label="Person" :value="BriefabsenderArt.PERSON"></el-option>
          <el-option label="Unternehmen" :value="BriefabsenderArt.UNTERNEHMEN"></el-option>
        </el-select>
      </validateable>
      <validateable-input
              v-if="hasType"
              v-model="props.model.name"
              :error="errorText(props.error.name)"
              :label="props.label.name"
              :no-margin="false"
              :prop="props.error.name"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.geschaeftsfuehrung"
              :error="errorText(props.error.geschaeftsfuehrung)"
              :label="props.label.geschaeftsfuehrung"
              :no-margin="false"
              :prop="props.error.geschaeftsfuehrung"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.strasse"
              :error="errorText(props.error.strasse)"
              :label="props.label.strasse"
              :no-margin="false"
              :prop="props.error.strasse"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.postleitzahl"
              :error="errorText(props.error.postleitzahl)"
              :label="props.label.postleitzahl"
              :no-margin="false"
              :prop="props.error.postleitzahl"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.ort"
              :error="errorText(props.error.ort)"
              :label="props.label.ort"
              :no-margin="false"
              :prop="props.error.ort"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.telefon"
              :error="errorText(props.error.telefon)"
              :label="props.label.telefon"
              :no-margin="false"
              :prop="props.error.telefon"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.telefax"
              :error="errorText(props.error.telefax)"
              :label="props.label.telefax"
              :no-margin="false"
              :prop="props.error.telefax"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.email"
              :error="errorText(props.error.email)"
              :label="props.label.email"
              :no-margin="false"
              :prop="props.error.email"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-if="hasType"
              v-model="props.model.internet"
              :error="errorText(props.error.internet)"
              :label="props.label.internet"
              :no-margin="false"
              :prop="props.error.internet"
              :size="props.size"
              @validate="emits('validate', path)"
      />
    </el-col>
    <el-col v-if="hasType" :span="12" class="uploader">
      <validateable
              :error="errorText(props.error.logo)"
              :label="props.label.logo"
              :prop="props.error.logo"
              :size="props.size"
      >
        <el-upload
                :auto-upload="false"
                :file-list="fileList"
                :on-change="onFileChange"
                :show-file-list="false"
        >
          <img
                  v-if="props.model.logo"
                  :src="props.model.logo"
                  alt="Logo"
          />
          <el-icon v-else class="uploader-icon">
            <Plus></Plus>
          </el-icon>
          <span v-if="props.model.logo" class="actions">
            <el-button
                    icon="delete"
                    link
                    @click.stop.prevent="fileList=[];props.model.logo=null"
            />
          </span>
        </el-upload>
      </validateable>
      <el-tooltip
              content="Die Skalierung wird mit min(200/Breite, 40/Höhe) abgeschätzt"
              placement="top-start"
      >
        <validateable-input
                v-if="hasType"
                model-value=""
                :error="errorText(props.error.logoSkalierung)"
                :label="props.label.logoSkalierung"
                :no-margin="false"
                :prop="props.error.logoSkalierung"
                :size="props.size"
                disabled
                @validate="emits('validate', path)"
        />
      </el-tooltip>
      <validateable-input
              v-model="props.model.registergericht"
              :error="errorText(props.error.registergericht)"
              :label="props.label.registergericht"
              :no-margin="false"
              :prop="props.error.registergericht"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-model="props.model.registernummer"
              :error="errorText(props.error.registernummer)"
              :label="props.label.registernummer"
              :no-margin="false"
              :prop="props.error.registernummer"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-model="props.model.umsatzsteuerid"
              :error="errorText(props.error.umsatzsteuerid)"
              :label="props.label.umsatzsteuerid"
              :no-margin="false"
              :prop="props.error.umsatzsteuerid"
              :size="props.size"
              @validate="emits('validate', path)"
      />
      <validateable-input
              v-model="props.model.ruecksendeangabe"
              :error="errorText(props.error.ruecksendeangabe)"
              :label="props.label.ruecksendeangabe"
              :no-margin="false"
              :prop="props.error.ruecksendeangabe"
              :size="props.size"
              @validate="emits('validate', path)"
      />
    </el-col>
    <el-col v-if="hasType" :span="12">

    </el-col>
    <el-col v-if="hasType" :span="24">
      <validateable
              :error="errorText(props.error.customFooter)"
              :label="`${props.label.footer} (${footerRowCount()}/6)`"
              :size="props.size"
      >
        <el-input
                v-model="props.model.customFooter"
                :size="props.size"
                rows="6"
                type="textarea"
                @change="emits('validate', props.path)"
        />
      </validateable>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.uploader {
  img {
    max-width: 100%;
  }

  .actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #0f0;
    opacity: 0;
    font-size: 20px;
    background-color: var(--el-overlay-color-lighter);
    transition: opacity var(--el-transition-duration);

    &:hover {
      opacity: 1;
    }

    .el-icon {
      font-size: 20px;
    }
  }

  .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
  }

  .el-upload:hover {
    border-color: var(--el-color-primary);
  }

  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 118px;
    height: 118px;
    text-align: center;
  }
}

</style>