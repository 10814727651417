<script generic="M extends Model<M>" lang="ts" setup>

import {Formatter} from "@lib/common/Formatter"
import RepositoryRadioGroup from "@lib/view/formitem/RepositoryRadioGroup.vue"
import FormItem from "@lib/view/formitem/FormItem.vue"
import {computed} from "vue"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {from} from "@lib/common/Functions"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {Model} from "@lib/common/model/Model"
import {Resource} from "@lib/model/Resource"
import {DebitorSepa} from "@generated/de/lohn24/model/debitor/DebitorSepa"
import IbanInput from "@lib/view/IbanInput.vue"
import {
    BereitstellungOptionRechnung,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"
import EnumCheckboxButtonGroup from "@lib/view/formitem/EnumCheckboxButtonGroup.vue"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"
import OptionaleAnschriftView from "@intranet/view/anschrift/OptionaleAnschriftView.vue"
import {_props} from "@lib/common/_props"
import AnschriftView from "@intranet/view/anschrift/AnschriftView.vue"

const props = withDefaults(
    defineProps<{
        path?: string
        controller: DebitorController<M>
        rechnungssteller?: boolean
        abrechnungssystem?: LohnabrechnungSystem
        rechnungsanschrift?: boolean
        postanschrift?: boolean
    }>(),
    {
        path: "debitor",
        abrechnungssystem: LohnabrechnungSystem.ADDISON,
        rechnungsanschrift: false,
        postanschrift: false,
        rechnungssteller: false,
    },
)

const emits = defineEmits<{
    validate: [path?: string]
}>()

const debitorViewModel = computed<DebitorViewModel>(() =>
    props.controller.model.value ?? new DebitorViewModel(from(Debitor, {})),
)

const emitValidate = (path?: string) => emits("validate", path)

function resolveValidationPath(path: string): string {
    return `${props.path ?? "debitor"}.${path}`
}

</script>

<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <h3>Stammdaten</h3>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              label="Rechnungssteller"
              property="rechnungssteller"
      >
        <template #default="{validate}">
          <repository-radio-group
                  v-model="debitorViewModel.firmaId"
                  :disabled="!rechnungssteller"
                  :repository="Resource.firma"
                  data-cy="debitor-rechnungssteller"
                  @change="validate(path)"
          />
        </template>

      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.name"
              label="Name"

      >
        <el-input
                v-model="debitorViewModel.name"
                data-cy="debitor-name"
                name="name"
                @blur="emitValidate"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.konto"
              label="Debitorenkonto"
      >
        <el-input
                v-model="debitorViewModel.konto"
                data-cy="debitor-konto"
                name="konto"
                @blur="emitValidate"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.mail"
              label="E-Mail"
      >
        <el-input
                v-model="debitorViewModel.mail"
                data-cy="debitor-mail"
                @blur="emitValidate"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.umsatzsteuerid"
              label="USt-IdNr."
      >
        <el-input
                v-model="debitorViewModel.umsatzsteuerid"
                data-cy="debitor-mail"
                @blur="emitValidate"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.rechnungszusatz"
              data-cy="debitor-rechnungszusatz"
              label="Rechnungszusatz"
      >
        <el-input v-model="debitorViewModel.rechnungszusatz" @blur="emitValidate"></el-input>
      </form-item>
    </el-col>
    <el-col :span="12">
      <h3>SEPA</h3>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.sepa"
              label="Mandatstyp"
      >
        <el-select v-model="debitorViewModel.sepa" clearable data-cy="debitor-sepa"
                   placeholder="Kein Sepamandat"
                   @change="$emit('validate', path)"
        >
          <el-option :value="DebitorSepa.BASISLASTSCHRIFT" label="Basislastschrift">Basislastschrift</el-option>
          <el-option :value="DebitorSepa.FIRMENLASTSCHRIFT" label="Firmenlastschrift">Firmenlastschrift</el-option>
        </el-select>
      </form-item>
      <div
              v-if="debitorViewModel.sepa === DebitorSepa.FIRMENLASTSCHRIFT || debitorViewModel.sepa === DebitorSepa.BASISLASTSCHRIFT"
      >
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.iban"
                label="IBAN"
        >
          <iban-input v-model="debitorViewModel.iban" data-cy="debitor-iban" @blur="emitValidate" />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.abweichenderKontoinhaber"
                label="Abw. Kontoinhaber"
        >
          <el-input v-model="debitorViewModel.abweichenderKontoinhaber"
                    data-cy="debitor-abweichenderKontoinhaber"
                    @blur="emitValidate"
          />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaMandatsreferenz"
                label="Mandatsreferenz"
        >
          <el-input
                  v-model="debitorViewModel.sepaMandatsreferenz"
                  data-cy="debitor-sepaMandatsreferenz"
                  @blur="emitValidate"
          />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaUnterschrift"
                label="Unterschrieben am"
        >
          <el-date-picker
                  v-model="debitorViewModel.sepaUnterschrift"
                  data-cy="debitor-sepaUnterschrift"
                  format="DD.MM.YYYY"
                  @change="emitValidate(props.path)"
          ></el-date-picker>
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaGueltigkeit"
                label="Gültig ab"
        >
          <el-date-picker
                  v-model="debitorViewModel.sepaGueltigkeit"
                  data-cy="debitor-sepaGueltigkeit"
                  format="DD.MM.YYYY"
                  @change="emitValidate(props.path)"
          ></el-date-picker>
        </form-item>

      </div>
      <form-item
              v-else
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.sepaVomKundenAbgelehnt"
              label="Sepamandat vom Kunden abgelehnt"
      >
        <el-checkbox
                v-model="debitorViewModel.sepaVomKundenAbgelehnt"
                data-cy="debitor-sepaVomKundenAbgelehnt"
                @blur="emitValidate"
        />
      </form-item>
    </el-col>
  </el-row>
  <br />
  <el-row :gutter="40">
    <el-col :span="12" />
    <el-col :span="12">
      <h3 class="heading">Datenbereitstellung</h3>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.bereitstellungRechnung"
              label="Rechnung"
      >
        <template #default="{validate}">
          <enum-checkbox-button-group
                  v-model="debitorViewModel.bereitstellungRechnung"
                  :label="{
                      [BereitstellungOptionRechnung.CLOUD]: 'LOHN24 Cloud',
                      [BereitstellungOptionRechnung.POST]: 'Post',
                      [BereitstellungOptionRechnung.FAX]: 'Fax',
                      [BereitstellungOptionRechnung.MAIL]: 'E-Mail',
                      [BereitstellungOptionRechnung.SYSTEM]: Formatter.bereitstellungAbrechnungssystem(abrechnungssystem),
                  }"
                  :options="Object.values(BereitstellungOptionRechnung)"
                  @change="validate(path)"
          />
        </template>
      </form-item>
    </el-col>

  </el-row>
  <br />
  <el-row :gutter="40">
    <el-col :span="12" v-if="props.rechnungsanschrift">

      <h3 class="heading">Rechnungsanschrift</h3>
      <anschrift-view
              :anschrift="debitorViewModel.rechnungsanschrift"
              :path="resolveValidationPath(_props<Debitor>().rechnungsanschrift)"
              @validate="emits('validate')"
      />
    </el-col>
    <el-col :span="12">
      <h3 class="heading">Abweichende Rechnungsanschrift</h3>
      <optionale-anschrift-view
              v-model:anschrift="debitorViewModel.abweichendeRechnungsanschrift"
              :path="resolveValidationPath(_props<Debitor>().abweichendeRechnungsanschrift)"
              @validate="emits('validate')"
      />
    </el-col>
    <el-col :span="12">
      <h3 class="heading">Postanschrift</h3>
      <optionale-anschrift-view
              v-model:anschrift="debitorViewModel.postanschrift"
              :path="resolveValidationPath(_props<Debitor>().postanschrift)"
              @validate="emits('validate')"
      />
    </el-col>
  </el-row>
</template>

<style scoped>
.el-tab-pane {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 20px;
}
</style>