import {DialogController} from "@lib/common/controller/DialogController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {computed, reactive, shallowRef, ShallowRef} from "vue"
import {DebitorEditDialogViewModel} from "@intranet/view/debitor/edit/DebitorEditDialogViewModel"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {ValidationModel, Validator} from "@lib/common/validator/Validator"
import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {ValidationProperties} from "@lib/common/validator/ValidationProperties"

export class DebitorEditDialogController extends DialogController {
    title = computed(() => this.viewModel.titel)
    debitorController: ShallowRef<DebitorController<any>>
    tabs = reactive([
        new EditDialogTab(
            "debitor",
            "Stammdaten",
            [
                new ValidationProperties("debitor", Object.values(Debitor.props)),
            ],
        ),
    ])

    constructor(debitor: Debitor) {
        super()
        this.debitorController = shallowRef(new DebitorController(new DebitorViewModel(debitor)))
    }

    get debitor(): Debitor {
        return this.debitorController.value.model.value.debitor
    }

    get viewModel() {
        return new DebitorEditDialogViewModel(this.debitor)
    }

    override modified() {
        return this.debitorController.value.modified
    }

    get validator() {
        return new Validator(
            new ValidationModel(
                "debitor",
                "debitor",
                () => this.debitor,
            )
        )
    }

    override async save(): Promise<void> {
        await this.debitorController.value.update()
    }
}