<script setup lang="ts">
import {Staat} from "@generated/de/lohn24/model/staat/Staat"
import {Id} from "@lib/common/model/Id"
import {Validateable, ValidateableInput} from "@lib/view"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Resource} from "@lib/model/Resource"
import {_props} from "@lib/common/_props"
import {Validator} from "@lib/common/validator/Validator"
import {IAnschriftUnternehmen} from "@intranet/view/anschrift/unternehmen/IAnschriftUnternehmen"
import {LabelProperty} from "@lib/types"
import {FontSize} from "@lib/common/Enums"
import {AnschriftUnternehmenViewModel} from "@intranet/view/anschrift/unternehmen/AnschriftUnternehmenViewModel"

const props = withDefaults(
    defineProps<
        IAnschriftUnternehmen
        & LabelProperty<IAnschriftUnternehmen>
        & { path: string }
        & { noMargin?: boolean, size?: FontSize }
    >(),
    {
        size: FontSize.small,
        noMargin: false,
    },
)

const emits = defineEmits<{
    "update:firma": [firma: string]
    "update:strasse": [strasse: string]
    "update:postleitzahl": [postleitzahl: string]
    "update:ort": [ort: string]
    "update:land": [land: Id<Staat>]
    "update:zusatz": [zusatz: string]
    "validate": [path?: string]
}>()

const errors = AnschriftUnternehmenViewModel.error(Validator.getErrorTextFactory(props.path))

</script>

<template>
  <validateable-input
          :model-value="props.firma"
          :error="errors[_props<IAnschriftUnternehmen>().firma]"
          :label="props.label.firma"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:firma', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.strasse"
          :error="errors[_props<IAnschriftUnternehmen>().strasse]"
          :label="props.label.strasse"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:strasse', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.postleitzahl"
          :error="errors[_props<IAnschriftUnternehmen>().postleitzahl]"
          :label="props.label.postleitzahl"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:postleitzahl', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.ort"
          :error="errors[_props<IAnschriftUnternehmen>().ort]"
          :label="props.label.ort"
          :size="props.size"
          :no-margin="props.noMargin"
          @update:modelValue="emits('update:ort', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable
          :error="errors[_props<IAnschriftUnternehmen>().land]"
          :label="props.label.land"
          :size="props.size"
          :no-margin="props.noMargin"
  >
    <repository-options
            :model-value="props.land"
            @update:modelValue="emits('update:land', $event as Id<Staat>)"
            :repository="Resource.land"
            @change="emits('validate', undefined)"

    />
  </validateable>
  <validateable-input
          :model-value="props.zusatz"
          :label="props.label.zusatz"
          :size="props.size"
          :no-margin="props.noMargin"
          :error="errors[_props<IAnschriftUnternehmen>().zusatz]"
          @update:modelValue="emits('update:zusatz', $event as string)"
          @validate="emits('validate', path)"
  />
</template>

<style scoped lang="scss">

</style>