import { IModel, IRuntimeTypeInformation, Id, ModelBuilder } from "@lib/index"
import { IKooperationspartnerDebitorBuilder, KooperationspartnerDebitorBuilder } from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerBuilder/KooperationspartnerDebitorBuilder"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import { IAnschrift, Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"

export interface IKooperationspartnerBuilder extends IModel {
	artikelkatalog: string | null
	sammelrechnung: string | null
	anschrift: IAnschrift
	keineRechnung: boolean
	sammelrechnungFestschreiben: number
	debitor: IKooperationspartnerDebitorBuilder | null
	kennung: string
	name: string
}

export class KooperationspartnerBuilder extends ModelBuilder<KooperationspartnerBuilder> {
	public artikelkatalog!: Id<Artikelkatalog> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public anschrift!: Anschrift
	public keineRechnung!: boolean
	public sammelrechnungFestschreiben!: number
	public debitor!: KooperationspartnerDebitorBuilder | null
	public kennung!: string
	public name!: string

	static _types: Record<keyof IKooperationspartnerBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikelkatalog: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		anschrift: { type: Anschrift },
		keineRechnung: { type: Boolean },
		sammelrechnungFestschreiben: { type: Number },
		debitor: { type: KooperationspartnerDebitorBuilder, nullable: true },
		kennung: { type: String },
		name: { type: String },
	}

	static props: Record<keyof IKooperationspartnerBuilder, string> = {
		id: "id",
		artikelkatalog: "artikelkatalog",
		sammelrechnung: "sammelrechnung",
		anschrift: "anschrift",
		keineRechnung: "keineRechnung",
		sammelrechnungFestschreiben: "sammelrechnungFestschreiben",
		debitor: "debitor",
		kennung: "kennung",
		name: "name",
	}

	constructor(_json: IKooperationspartnerBuilder) {
		super(_json)
	}
}
