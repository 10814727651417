import {ValidationProperties} from "@lib/common/validator/ValidationProperties"

export class EditDialogTab {

    constructor(
        public name: string,
        public label: string,
        public types: ValidationProperties[],
        public disabled: () => boolean = () => false,
        public deny?: boolean,
    ) {
    }

    static availableTabs(tabs: EditDialogTab[]): EditDialogTab[] {
        return tabs.filter(it => !it.deny)
    }

    errorCount() {
        return this.types.sumBy(it => it.errorSum())
    }

    available(): boolean {
        return !this.deny
    }
}