import {Validator} from "@lib/common/validator/Validator"
import {nextTick} from "vue"

export class ValidationService {
    constructor(private validator: Validator) {

    }

    async validate(path?: string): Promise<void> {
        await nextTick()
        await this.validator.validate(path)
    }
}