<script setup lang="ts">

import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {capitalize, debounce} from "lodash"
import AnschriftPerson from "@intranet/view/anschrift/person/AnschriftPerson.vue"
import AnschriftUnternehmen from "@intranet/view/anschrift/unternehmen/AnschriftUnternehmen.vue"
import AnschriftPostfach from "@intranet/view/anschrift/postfach/AnschriftPostfach.vue"
import {computed, watch} from "vue"
import {assertNotNull, from} from "@lib/common/Functions"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {AnschriftViewModel} from "@intranet/view/anschrift/AnschriftViewModel"

const props = withDefaults(
    defineProps<{
        anschrift: Anschrift | null
        disabled?: boolean
        path: string
    }>(),
    {
        disabled: false,
        optional: false,
    },
)

const emits = defineEmits<{
    "update:anschrift": [anschrift: null | Anschrift],
    "validate": [path?: string]
}>()

const optionaleArt = computed<AnschriftArt | null>({
    get() {
        return props.anschrift?.art ?? null
    },
    set(value: AnschriftArt | null) {
        if ((value?.toNullIfEmpty() ?? null) === null)
            return emits("update:anschrift", null)

        if (props.anschrift === null) {
            const anschrift = from(Anschrift, {art: assertNotNull(value)})
            return emits("update:anschrift", anschrift)
        }
        props.anschrift.art = assertNotNull(value)
    },
})

const optionaleAnschrift = computed<AnschriftViewModel | null>(() => {
    return props.anschrift ? new AnschriftViewModel(props.anschrift) : null
})

const debouncedValidate = debounce(() => emits("validate"), 1000, {trailing: true, leading: false})

watch(optionaleAnschrift, () => {
    debouncedValidate()
}, {deep: true})

</script>

<template>
  <el-form-item :label="AnschriftViewModel.label.art">
    <el-select v-model="optionaleArt" :disabled="disabled" name="art" clearable>
      <el-option
              v-for="art in AnschriftArt"
              :key="art"
              :label="capitalize(art)"
              :value="art"
              :data-cy="`anschrift-art-${art}`"
      />
    </el-select>
  </el-form-item>
  <anschrift-person
          v-if="optionaleAnschrift?.art === AnschriftArt.PERSON"
          v-model:titel="optionaleAnschrift.PERSON.titel"
          v-model:anrede="optionaleAnschrift.PERSON.anrede"
          v-model:vorname="optionaleAnschrift.PERSON.vorname"
          v-model:nachname="optionaleAnschrift.PERSON.nachname"
          v-model:strasse="optionaleAnschrift.PERSON.strasse"
          v-model:postleitzahl="optionaleAnschrift.PERSON.postleitzahl"
          v-model:ort="optionaleAnschrift.PERSON.ort"
          v-model:land="optionaleAnschrift.PERSON.land"
          v-model:zusatz="optionaleAnschrift.PERSON.zusatz"
          :label="AnschriftViewModel.label.PERSON"
          :path="props.path"
          @validate="emits('validate', path)"
  />
  <anschrift-unternehmen
          v-if="optionaleAnschrift?.art === AnschriftArt.UNTERNEHMEN"
          v-model:firma="optionaleAnschrift.UNTERNEHMEN.firma"
          v-model:strasse="optionaleAnschrift.UNTERNEHMEN.strasse"
          v-model:postleitzahl="optionaleAnschrift.UNTERNEHMEN.postleitzahl"
          v-model:ort="optionaleAnschrift.UNTERNEHMEN.ort"
          v-model:land="optionaleAnschrift.UNTERNEHMEN.land"
          v-model:zusatz="optionaleAnschrift.UNTERNEHMEN.zusatz"
          :label="AnschriftViewModel.label.UNTERNEHMEN"
          :path="props.path"
          @validate="emits('validate', path)"
  />
  <anschrift-postfach
          v-if="optionaleAnschrift?.art === AnschriftArt.POSTFACH"
          v-model:name="optionaleAnschrift.POSTFACH.name"
          v-model:strasse="optionaleAnschrift.POSTFACH.strasse"
          v-model:postleitzahl="optionaleAnschrift.POSTFACH.postleitzahl"
          v-model:ort="optionaleAnschrift.POSTFACH.ort"
          v-model:land="optionaleAnschrift.POSTFACH.land"
          v-model:zusatz="optionaleAnschrift.POSTFACH.zusatz"
          :label="AnschriftViewModel.label.POSTFACH"
          :path="props.path"
          @validate="emits('validate', path)"
  />
</template>

<style scoped lang="scss">

</style>