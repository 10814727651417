import {OptionalModelController} from "@lib/common/controller/OptionalModelController"
import {AnschriftViewModel} from "@intranet/view/anschrift/old/AnschriftViewModel"
import {computed, ref, Ref} from "vue"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Model} from "@lib/common/model/Model"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {from} from "@lib/common/Functions"

export class OptionalAnschriftController<L extends Model<L>> extends OptionalModelController<AnschriftViewModel> {

    static build<L extends Model<L>>(anschrift: Anschrift | null, relation: RepositoryRelation<L, Anschrift>) {
        return new OptionalAnschriftController(new AnschriftViewModel(anschrift ?? from(Anschrift, {})), relation)
    }

    dataSaveFailedMessage = "Anschrift"
    dataSavedSuccesfullyMessage = "Anschrift"

    effectiveModel = computed(() => this.editableValue.value)
    private art!: Ref<AnschriftArt | "">
    /**
     * wird benötigt, um optionale Anschriften zu managen
     */
    artProxy = computed<AnschriftArt | "">({
        get: () => this.art.value,
        set: value => {
            this.art.value = value
            if (value !== "")
                this.editableValue.value.art = value
            if (this.editableValue.value.anschrift.id?.value !== null && value === "") {
                this.editableValue.value.bearbeitungszustand.flagDeleted()
            } else if (this.editableValue.value.anschrift.id?.value !== null && value !== "")
                this.editableValue.value.bearbeitungszustand.unflagDeleted()
        },
    })

    constructor(anschrift: AnschriftViewModel, public relation: RepositoryRelation<L, Anschrift>, optional = true) {
        super(
            anschrift,
            () => !optional || this.art.value !== "",
        )
        this.art = ref((optional && this.editableValue.value.isEmpty()) ? "" : this.editableValue.value.art)
    }

    get isEmpty() {
        return this.editableValue.value.isEmpty()
    }

    get isCleared(): boolean {
        return this.art.value === ""
    }

    get isNotEmpty() {
        return !this.isEmpty
    }

    override get modified(): boolean {
        // das ViewModel ist geändert und
        return (this.artProxy.value.isNotEmpty() && super.modified)
            // das VieModel kann gelöscht geflagt sein und Art muss dann leer sein
            || (this.editableValue.value.bearbeitungszustand.deleted && this.artProxy.value.isEmpty())
    }

    async create(id: Id<L>): Promise<AnschriftViewModel> {
        this.model.value?.flagCreated()
        return super.update(id)
    }

    async update(id: Id<L>): Promise<AnschriftViewModel> {
        return super.update(id)
    }

    protected async createModel(model: AnschriftViewModel, parent: Id<L>): Promise<AnschriftViewModel> {
        if (model.isEmpty()) return model
        const anschrift = await this.relation.create(parent, model.anschrift)
        return new AnschriftViewModel(anschrift)
    }

    protected async updateModel(model: AnschriftViewModel, parent: Id<L>): Promise<AnschriftViewModel> {
        if (model.isEmpty() && model.anschrift.id?.value !== null || model.bearbeitungszustand.deleted) {
            await this.relation.delete(parent, model.anschrift)
            return new AnschriftViewModel(from(Anschrift, {}))
        }
        if (model.changed) {
            const anschrift = await this.relation.update(parent, model.anschrift)
            return new AnschriftViewModel(anschrift)
        }
        return model
    }

}

