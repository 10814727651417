import {ModelBuilder} from "@lib/common/model/ModelBuilder"
import {Model} from "@lib/common/model/Model"
import {Constructable, RecordOf} from "@lib/types"
import Api from "@lib/common/axios/Api"
import {repositoryConfig} from "@lib/common/repository/RepositoryConfig"
import {AxiosRequestConfig} from "axios"
import {Id} from "@lib/common/model/Id"
import {
    RepositoryDispatchMap,
    RepositoryEvent,
    RepositoryEventMap,
    RepositoryEventTarget,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"
import {Validator} from "@lib/common/validator/Validator"
import {EntityNotFoundException} from "@lib/common/axios/AxiosError"
import {from} from "@lib/common/Functions"

export type BuilderRepostioryEvents = RepostioryEvents | "finalize"
export type BuilderRepositoryEventMap<B, M> = RepositoryEventMap<B> & { "finalize": (model: M) => void }
export type BuilderRepositoryDispatchMap<B, M> = RepositoryDispatchMap<B> & { "finalize": M }

export class BuilderRepository<B extends ModelBuilder<B>, M extends Model<M>> {

    eventTarget = new RepositoryEventTarget<B>()

    private endpoint = "build"

    constructor(
        public path: string,
        public type: Constructable<B>,
        public modelType: Constructable<M>,
        protected api: Api = Api.Instance(repositoryConfig.baseURL),
    ) {
    }

    async all<K>(params?: K): Promise<Array<B>> {
        const result = await this.api.get<RecordOf<B>[]>(this.route(), params)
        return result.map((m) => from(this.type, m) as B)
    }

    async get<K>(id: Id<B>, params?: K): Promise<B> {
        const result = await this.api.get<RecordOf<B>>(this.route(id.toString()), params)
        const deserialised = from(this.type, result) as B
        this.dispatchEvent("get", deserialised)
        return deserialised
    }

    async find(id: Id<B>): Promise<B | null> {
        try {
            return await this.get(id)
        } catch (e) {
            if (e instanceof EntityNotFoundException) {
                return null
            }
            throw e
        }

    }

    async insert(builder: B) {
        const result = await this.post(builder)
        const deserialized = from(this.type, result) as B
        this.dispatchEvent("update", deserialized)
        return deserialized
    }

    async update(builder: B) {
        const result = await this.put(builder)
        const deserialized = from(this.type, result) as B
        this.dispatchEvent("update", deserialized)
        return deserialized
    }

    async finalize(builder: B): Promise<M> {
        const model = await this.postFinalize(builder)
        this.dispatchEvent("finalize", model)
        return model
    }

    async validate(builder: B, uniqueName?: string) {
        return await Validator.validate(this.route(), builder, uniqueName)
    }

    async delete(builder: Id<B>): Promise<void> {
        await this.api.delete<RecordOf<B>>(this.route(builder.toString()))
        this.dispatchEvent("delete", builder)
    }

    addEventListener<L extends BuilderRepostioryEvents>(type: L, cb: BuilderRepositoryEventMap<B, M>[L]) {
        //@ts-expect-error is passt schoan
        return this.eventTarget.addEventListener.bind(this.eventTarget)(type, cb)
    }

    removeEventListener<L extends BuilderRepostioryEvents>(type: L, cb: BuilderRepositoryEventMap<B, M>[L]) {
        //@ts-expect-error is passt schoan
        return this.eventTarget.removeEventListener.bind(this.eventTarget)(type, cb)
    }

    dispatchEvent<L extends BuilderRepostioryEvents>(type: L, data: BuilderRepositoryDispatchMap<B, M>[L]) {
        return this.eventTarget.dispatchEvent.bind(this.eventTarget)(new RepositoryEvent(type, data))
    }

    protected route(path: string | null = null): string {
        const result = path === null ? `/${this.path}/${this.endpoint}` : `/${this.path}/${this.endpoint}/${path}`
        return result.replace("//", "/")
    }

    protected async post(builder: B, path?: string, config?: AxiosRequestConfig): Promise<B> {
        const partial = await this.api.post<RecordOf<B>>(this.route(path), builder.toJSON(), config)
        return this.deserialize(partial)
    }

    protected async put(builder: B, config?: AxiosRequestConfig): Promise<B> {
        const partial = await this.api.put<RecordOf<B>>(this.route(builder.id.toString()), builder.toJSON(), config)
        return this.deserialize(partial)
    }

    protected async postFinalize(builder: B, config?: AxiosRequestConfig): Promise<M> {
        const partial = await this.api.post<RecordOf<M>>(this.route("finalize"), builder.toJSON(), config)
        return new this.modelType(partial) as M
    }

    private deserialize(result: RecordOf<B> | RecordOf<B>[]) {
        if (Array.isArray(result))
            return result.map(it => this.deserialize(it))
        return from(this.type, result)
    }
}