import {ModelBuilderController} from "@lib/common/controller/ModelBuilderController"
import {DebitorBuilder} from "@generated/de/lohn24/model/debitor/DebitorBuilder"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Resource} from "@lib/model/Resource"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {Id} from "@lib/common/model/Id"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {from} from "@lib/common/Functions"
import {ShallowRef} from "vue"

export class DebitorBuilderController extends ModelBuilderController<DebitorBuilder, Debitor> {
    builderRepository = Resource.debitor.builder

    constructor(
        private debitorController: ShallowRef<DebitorController<any>>,
    ) {
        super()
    }

    fromModel(
        debitor: Debitor,
    ) {
        return from(
            DebitorBuilder,
            {
                konto: debitor.konto,
                name: debitor.name,
                status: debitor.status,
                iban: debitor.iban,
                abweichenderKontoinhaber: debitor.abweichenderKontoinhaber,
                sepa: debitor.sepa,
                sepaMandatsreferenz: debitor.sepaMandatsreferenz,
                sepaUnterschrift: debitor.sepaUnterschrift,
                sepaGueltigkeit: debitor.sepaGueltigkeit,
                umsatzsteuerid: debitor.umsatzsteuerid,
                rechnungszusatz: debitor.rechnungszusatz,
                rechnungssteller: debitor.rechnungssteller,
                mail: debitor.mail,
                sepaVomKundenAbgelehnt: debitor.sepaVomKundenAbgelehnt,
                postanschrift: debitor.postanschrift,
                rechnungsanschrift: debitor.rechnungsanschrift,
                abweichendeRechnungsanschrift: debitor.abweichendeRechnungsanschrift,
                bereitstellungRechnung: debitor.bereitstellungRechnung,
            },
        )
    }

    getBuilder(): DebitorBuilder {

        const debitor = this.debitorController.value.model.value.debitor
        const debitorBuilder = this.fromModel(
            debitor,
        )
        if (this.builderId)
            debitorBuilder.id = new Id(this.builderId)//new Id(this.builderId?.value)
        return debitorBuilder
    }

    setBuilder(builder: Nullable<DebitorBuilder>) {
        this.builderId = builder?.id ?? null
        if (!builder) return
        this.debitorController.value = new DebitorController(new DebitorViewModel(this.getDebitor(builder)))
    }

    getDebitor(builder: DebitorBuilder) {
        return from(Debitor, {
            konto: builder.konto,
            name: builder.name,
            status: builder.status,
            iban: builder.iban,
            abweichenderKontoinhaber: builder.abweichenderKontoinhaber,
            sepa: builder.sepa,
            sepaMandatsreferenz: builder.sepaMandatsreferenz,
            sepaUnterschrift: builder.sepaUnterschrift,
            sepaGueltigkeit: builder.sepaGueltigkeit,
            umsatzsteuerid: builder.umsatzsteuerid,
            rechnungszusatz: builder.rechnungszusatz,
            rechnungssteller: builder.rechnungssteller,
            mail: builder.mail,
            sepaVomKundenAbgelehnt: builder.sepaVomKundenAbgelehnt,
            bereitstellungRechnung: builder.bereitstellungRechnung,
        })
    }
}