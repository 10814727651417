<script lang="ts" setup>
import {Component, computed} from "vue"

const emits = defineEmits<{
    "close": []
}>()

const props = defineProps<{
    label: string
    action: (event: MouseEvent) => void
    icon: string | Component
    enabled: boolean
}>()

function onClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (!props.enabled)
        return

    if (props.action)
        props.action(event)
    emits("close")
}

const liClassNames = computed<string>(() => {
    const names = ["context-menu-item"]
    if (!props.enabled)
        names.push("inactive")

    return names.join(" ")
})

const labelClassNames = computed<string>(() => {
    const names = ["context-menu-item__label"]
    if (!props.enabled)
        names.push("inactive")

    return names.join(" ")
})
</script>

<template>
  <li :class="liClassNames" @click="onClick" @mousedown.prevent @mouseup.prevent>
    <span class="icon-prepend">
      <slot name="icon">
        <component :is="icon" v-if="icon" />
      </slot>
    </span>
    <span :class="labelClassNames">{{ label }}</span>
  </li>
</template>

<style lang="scss">
.context-menu-item {
  &.inactive {
    color: var(--el-disabled-text-color);
    cursor: not-allowed;
  }

  span.icon-prepend {
    margin-left: calc(0.5 * var(--context-menu-item-padding) - var(--context-menu-item-padding));
    margin-right: calc(var(--context-menu-item-padding) - 0.5 * var(--context-menu-item-padding));
    width: var(--font-size);
  }

  span.context-menu-item__label {
    width: calc(90%);
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>