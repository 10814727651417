import {ViewModel} from "@lib/common/model/ViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {_props} from "@lib/common/_props"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {ResourceCache} from "@lib/model/Resource"

export class AnschriftSummaryViewModel extends ViewModel<AnschriftSummaryViewModel> {

    static label = {
        headline: "Anschrift",
        art: "Art",
        name: "Name",
        person: "Natürliche Person",
        unternehmen: "Unternehmen",
        postfach: "Postfach",

        vorname: "Vorname",
        nachname: "Nachname",
        titel: "Titel",
        firma: "Firma",
        strasse: "Straße",
        hausnummer: "Nummer",
        postleitzahl: "Postleitzahl",
        ort: "Ort",
        land: "Land",
        zusatz: "Zusatz",
    }
    static properties = _props<InstanceType<typeof this>>()
    anschrift: Anschrift

    constructor(anschrift: Anschrift) {
        super()
        this.anschrift = anschrift
    }

    get name() {
        return (this.anschrift.art === AnschriftArt.UNTERNEHMEN && this.unternehmenName) ||
            (this.anschrift.art === AnschriftArt.POSTFACH && this.postfachName) ||
            (this.anschrift.art === AnschriftArt.PERSON && this.personName)
    }

    get dataHorizontal() {
        return [
            {
                [AnschriftSummaryViewModel.properties.name]: this.name,
                [AnschriftSummaryViewModel.properties.strasse]: this.strasse,
                [AnschriftSummaryViewModel.properties.ort]: this.ort,
            },
        ]
    }

    get nameLand(): string {
        const land = ResourceCache.land.mapped(this.anschrift.land, (it) => it?.name).value ?? ""
        if (land === "Deutschland")
            return ""
        return land
    }

    get dataVertical() {
        return [
            {
                key: "Name",
                value:
                    (this.anschrift.art === AnschriftArt.UNTERNEHMEN && this.unternehmenName) ||
                    (this.anschrift.art === AnschriftArt.POSTFACH && this.postfachName) ||
                    (this.anschrift.art === AnschriftArt.PERSON && this.personName),
            },
            {
                key: "Anschrift",
                value: `${this.anschrift.strasse}, ${this.anschrift.postleitzahl} ${this.anschrift.ort} ${this.nameLand}`.trimEnd(),
            },
        ]
    }

    get unternehmenName() {
        return this.anschrift.name1
    }

    get postfachName(): string {
        return this.anschrift.name1
    }

    get personName() {
        return `${this.anschrift.titel || ""} ${this.vorname || ""} ${this.nachname}`.trim()
    }

    get vorname() {
        return this.anschrift.name2 ?? ""
    }

    get nachname() {
        return this.anschrift.name1
    }

    get strasse() {
        return `${this.anschrift.strasse}`.trim()
    }

    get ort() {
        return `${this.anschrift.postleitzahl} ${this.anschrift.ort}`
    }

    override equals(other: ViewModel<AnschriftSummaryViewModel>): boolean {
        if (other instanceof AnschriftSummaryViewModel) {
            return this.anschrift.id.value === other.anschrift.id.value
        }
        return false
    }
}