import {DialogController} from "@lib/common/controller/DialogController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {computed, shallowReactive} from "vue"
import {DebitorSummaryDialogViewModel} from "@intranet/view/debitor/summary/DebitorSummaryDialogViewModel"
import {DebitorEditDialog} from "@intranet/view/debitor/edit/DebitorEditDialog"

export class DebitorSummaryDialogController extends DialogController {
    title = computed(() => this.viewModel.titel)

    readonly debitor: Debitor

    constructor(debitor: Debitor) {
        super()
        this.debitor = shallowReactive(debitor)
    }

    get viewModel() {
        return new DebitorSummaryDialogViewModel(this.debitor)
    }

    async edit() {
        return await DebitorEditDialog.show(this.debitor.id)
    }
}