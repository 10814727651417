import {DialogController} from "@lib/common/controller/DialogController"
import {computed, onUnmounted, shallowRef, ShallowRef, WatchStopHandle} from "vue"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {KooperationspartnerViewModel} from "@intranet/view/kooperationspartner/KooperationspartnerViewModel"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {AnschriftController} from "@intranet/view/anschrift/old/AnschriftController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {KontaktListController} from "@intranet/view/kontakt/KontaktListController"
import {
    KooperationspartnerEditDialogViewModel,
} from "@intranet/view/kooperationspartner/edit/KooperationspartnerEditDialogViewModel"
import {ValidationModel, Validator} from "@lib/common/validator/Validator"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {
    KooperationspartnerSummaryDialog,
} from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialog"
import {
    KooperationspartnerModelController,
} from "@intranet/view/kooperationspartner/KooperationspartnerModelController"
import {OptionalAnschriftController} from "@intranet/view/anschrift/old/OptionalAnschriftController"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {
    KooperationspartnerBriefabsenderController,
} from "@intranet/view/kooperationspartner/briefabsender/KooperationspartnerBriefabsenderController"
import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {ValidationProperties} from "@lib/common/validator/ValidationProperties"
import {_props} from "@lib/common/_props"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Briefabsender} from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import {from} from "@lib/common/Functions"
import {Resource} from "@lib/model/Resource"

export class KooperationspartnerEditDialogController extends DialogController {
    title = computed(() => {
        return this.viewModel.titel
    })
    kooperationspartnerController: ShallowRef<KooperationspartnerModelController>
    debitorController: ShallowRef<DebitorController<Kooperationspartner> | null> = shallowRef(null)
    kontaktController: ShallowRef<KontaktListController<any> | null> = shallowRef(null)
    anschriftController: ShallowRef<AnschriftController<Mandant> | null> = shallowRef(null)
    postanschriftController: ShallowRef<OptionalAnschriftController<Debitor> | null> = shallowRef(null)
    briefabsenderController: ShallowRef<KooperationspartnerBriefabsenderController | null> = shallowRef(null)

    tabs = [
        new EditDialogTab(
            "stammdaten",
            "Stammdaten",
            [
                new ValidationProperties(
                    "kooperationspartner",
                    [
                        _props<Kooperationspartner>().anschrift,
                        _props<Kooperationspartner>().sammelrechnung,
                        _props<Kooperationspartner>().artikelkatalog,
                        _props<Kooperationspartner>().debitor,
                        _props<Kooperationspartner>().name,
                        _props<Kooperationspartner>().kennung,
                        _props<Kooperationspartner>().keineRechnung,
                        _props<Kooperationspartner>().sammelrechnungFestschreibenTag,
                        // _props<Kooperationspartner>().aktiveMandanten,
                        _props<Kooperationspartner>().zammadId,
                    ],
                ),
                new ValidationProperties(
                    _props<Kooperationspartner>().anschrift,
                    Object.values(Anschrift.props),
                ),
            ],
        ),
        new EditDialogTab(
            "debitor",
            "Rechnung",
            [
                new ValidationProperties(
                    _props<Kooperationspartner>().debitor,
                    Object.values(Debitor.props),
                ),
                new ValidationProperties(
                    _props<Debitor>().postanschrift,
                    Object.values(Anschrift.props),
                ),
            ],
            () => !this.needsDebitor,
        ),
        new EditDialogTab(
            "kontakt",
            "Kontakte",
            [
                new ValidationProperties(
                    "kontakt",
                    Object.values(Kontakt.props),
                ),
            ],
        ),
        new EditDialogTab(
            "briefabsender",
            "Briefabsender",
            [
                new ValidationProperties(
                    "kooperationspartner",
                    [
                        _props<Briefabsender>().name,
                        _props<Briefabsender>().briefabsenderArt,
                        _props<Briefabsender>().email,
                        _props<Briefabsender>().geschaeftsfuehrung,
                        _props<Briefabsender>().internet,
                        _props<Briefabsender>().logo,
                        _props<Briefabsender>().logoSkalierung,
                        _props<Briefabsender>().ort,
                        _props<Briefabsender>().postleitzahl,
                        _props<Briefabsender>().registergericht,
                        _props<Briefabsender>().registernummer,
                        _props<Briefabsender>().ruecksendeangabe,
                        _props<Briefabsender>().strasse,
                        _props<Briefabsender>().telefax,
                        _props<Briefabsender>().telefon,
                        _props<Briefabsender>().umsatzsteuerid,
                    ],
                ),
            ],
        ),
    ]

    constructor(kooperationspartner: Kooperationspartner) {
        super()
        this.kooperationspartnerController = shallowRef(
            new KooperationspartnerModelController(
                new KooperationspartnerViewModel(
                    kooperationspartner,
                ),
            ),
        )
    }

    get viewModel() {
        return new KooperationspartnerEditDialogViewModel(this.kooperationspartner)
    }

    get needsDebitor() {
        return this.viewModel.needsDebitor
    }

    get validator() {
        return new Validator(
            new ValidationModel(
                "kooperationspartner",
                "kooperationspartner",
                () => this.viewModel.kooperationspartner,
            ),
            new ValidationModel(
                "debitor",
                "debitor",
                () => this.debitorController.value?.model?.value?.debitor ?? {} as Debitor,
                () => !this.debitorController.value,
            ),
            new ValidationModel(
                "anschrift",
                "rechnungsanschrift",
                () => this.anschriftController.value?.model?.value?.anschrift ?? {} as Anschrift,
                () => !this.anschriftController.value,
            ),
            new ValidationModel(
                "anschrift",
                "postanschrift",
                () => this.postanschriftController.value?.model?.value?.anschrift ?? from(Anschrift, {}),
                () => !this.postanschriftController.value,
            ),
            new ValidationModel<Briefabsender>(
                "briefabsender",
                "briefabsender",
                () => this.briefabsenderController.value?.model.value?.briefabsender ?? from(Briefabsender, {}),
                () => !this.briefabsenderController.value,
            ),
        )
    }

    private get kooperationspartner(): Kooperationspartner {
        return this.kooperationspartnerController.value.model.value.kooperationspartner
    }

    modified(): boolean {
        return (this.kooperationspartnerController.value?.modified || false)
            || (this.debitorController.value?.modified || false)
            || (this.kontaktController.value?.modified || false)
            || (this.anschriftController.value?.modified || false)
            || (this.briefabsenderController.value?.modified || false)
    }

    override async save() {
        try {
            // TODO: falls sammelrechnung oder katalog geändert werden können die BelegBuilder kaputt gehen
            const koop = (await (this.kooperationspartnerController.value?.update()))
            if (koop) {
                const debitor: DebitorViewModel | undefined = await (this.debitorController.value?.update(koop.kooperationspartner.id))
                if (debitor) {
                    if (debitor.postanschrift == null)
                        this.postanschriftController.value?.create(debitor.debitor.id)
                    else
                        this.postanschriftController.value?.update(debitor.debitor.id)
                }
                this.anschriftController.value?.update(koop.kooperationspartner.id)
                this.kontaktController.value?.update()
                this.briefabsenderController.value?.update(koop.kooperationspartner.id)
            }
            await DialogController.closeDialogs()
        } catch (e) {
            if (e instanceof ValidationError)
                return undefined
        }
    }

    protected override async onClose(): Promise<void> {

        if (ComponentRenderer.activeInstanceIsOf(KooperationspartnerSummaryDialog)) {
            return super.onClose()
        } else {
            await KooperationspartnerSummaryDialog.show(this.kooperationspartner.id)
        }
    }

    private watchStopHandle: WatchStopHandle | null = null

    subscribe() {
        this.watchStopHandle && this.watchStopHandle()
        this.watchStopHandle = DebitorController.synchronizeAnschriftWatcher(
            this.kooperationspartner,
            Resource.kooperationspartner.debitor,
            this.debitorController,
            () => this.anschriftController.value?.model.value.anschrift,
        )
    }

    unsubscribe() {
        this.watchStopHandle && this.watchStopHandle()
        this.watchStopHandle = null
    }

    override useController(): void {
        super.useController()
        this.subscribe()
        onUnmounted(() => {
            this.unsubscribe()
        })
    }
}