import {Repository} from "@lib/common/repository/Repository"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {from} from "@lib/common/Functions"
import {DebitorBuilder} from "@generated/de/lohn24/model/debitor/DebitorBuilder"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Ticketgruppe} from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"

export class DebitorRepository extends Repository<Debitor> {
    mandant = this.buildRelation(Mandant, "mandant")
    builder = this.buildBuilder(DebitorBuilder)

    constructor() {
        super(Debitor, "debitor")
    }

    async anschriftentraeger(debitor: Id<Debitor>, anschrift: Id<Anschrift>): Promise<Blob> {
        return await this.api.getBlob(this.route(`${debitor}/anschriftentraeger`), {
            anschrift: anschrift.value,
        })
    }

    override async all(params?): Promise<Debitor[]> {
        return super.all(params)
    }

    async ticketgruppe(id: Id<Debitor>): Promise<Ticketgruppe> {
        const result = await this.api.get<Partial<Ticketgruppe>>(this.route(`${id}/ticketgruppe`))
        return from(Ticketgruppe, result)
    }
}