import {ViewModel} from "@lib/common/model/ViewModel"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {_props} from "@lib/common/_props"
import {ValidateableListItem} from "@lib/common/validator/ValidateableListItem"
import {Formatter} from "@lib/common/Formatter"
import {KatalogArtikelPosition} from "@generated/de/lohn24/model/beleg/builder/KatalogArtikelPosition"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"

export class KatalogPositionViewModel extends ViewModel<KatalogPositionViewModel> implements ValidateableListItem {
    static properties = _props<InstanceType<typeof this>>()
    static label = {
        [this.properties.text]: "Text",
        [this.properties.menge]: "Menge",
        [this.properties.notiz]: "Notiz",
        [this.properties.netto]: "Netto",
        [this.properties.summe]: "Summe",
    }
    validationIndex: number = 0
    readonly katalogArtikelPosition: KatalogArtikelPosition
    readonly artikelkatalogArtikel: ArtikelkatalogArtikel
    readonly artikel: Artikel
    readonly mandantArtikel: Nullable<MandantArtikel>

    constructor(katalogArtikelPosition: KatalogArtikelPosition, artikel: Pair<ArtikelkatalogArtikel, Artikel>, mandantArtikel?: MandantArtikel) {
        super()
        //katalogArtikelPosition, artikel ?? new Artikel(), mandantArtikel ?? new MandantArtikel()
        this.katalogArtikelPosition = this.registerShallowReactive(katalogArtikelPosition)
        this.artikelkatalogArtikel = artikel.first
        this.artikel = artikel.second
        this.mandantArtikel = mandantArtikel ?? null
    }

    get menge() {
        return this.katalogArtikelPosition.menge
    }

    set menge(value: number) {
        this.katalogArtikelPosition.menge = value
    }

    get text() {
        return this.katalogArtikelPosition.text ?? ""
    }

    set text(value: string) {
        this.katalogArtikelPosition.text = value ? value : null
    }

    get notiz() {
        return this.katalogArtikelPosition.notiz ?? ""
    }

    set notiz(value: string) {
        this.katalogArtikelPosition.notiz = value
    }

    get artikelName() {
        return this.mandantArtikel?.name ? `${this.mandantArtikel.name}` : this.artikel ? `${this.artikel.name}` : ""
    }

    get netto() {
        const netto = this.preisFromArtikel()
        return Formatter.preis(netto)
    }

    get summe() {
        const netto = this.preisFromArtikel()
        return Formatter.preis(netto * this.katalogArtikelPosition.menge)
    }

    textIsBlanc() {
        return this.katalogArtikelPosition.text === ""
    }

    hasMandantPreis() {
        return !!this.mandantArtikel?.netto ?? false
    }

    override equals(other: ViewModel<KatalogPositionViewModel>): boolean {
        if (other instanceof KatalogPositionViewModel) {
            if (other.text === this.text && other.artikelName === this.artikelName && other.menge === this.menge) {
                return true
            }
        }
        return false
    }

    private preisFromArtikel(): number {
        if (this.artikel)
            return this.mandantArtikel?.netto ?? this.artikelkatalogArtikel.netto
        throw new Error("Belegerstellung hat keinen Artikel/ ist ein freitext Artikel")
    }

    get sachbearbeiterabschluss(): boolean {
        return this.mandantArtikel?.sachbearbeiterabschluss ?? this.artikelkatalogArtikel.sachbearbeiterabschluss
    }

    get rechnungsabschluss(): boolean {
        return this.mandantArtikel?.rechnungsabschluss ?? this.artikelkatalogArtikel.rechnungsabschluss
    }
}