<template>
  <kontakt
          :id="modelValue"
          :key="modelValue.value"
          :controller="controllerKontakte"
          :relation="Resource.organisation.kontakt"
          @update:controller="$emit('update:controllerKontakte', $event)"
  >
    <template #pre-table>
      <el-row v-if="keinDirektkontakt">
        <el-col>
          <h4>
            <el-text class="mx-1" type="warning">
              Dieser Mandant ist auf kein Direktkontakt eingestellt.
            </el-text>
          </h4>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
                  :error="getOrganisationNameError()"
                  :label="OrganisationViewModel.label.name"
          >
            <repository-options
                    :createable="createable"
                    :model-value="modelValue"
                    :repository="Resource.organisation"
                    edit-dialog="Neue Organisation anlegen"
                    editable
                    model-key="name"
                    @change="organisation = $event"
                    @update:modelValue="$emit('update:modelValue', $event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12"></el-col>
      </el-row>
    </template>
  </kontakt>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {Id} from "@lib/common/model/Id"
import {KontaktListController} from "@intranet/view/kontakt/KontaktListController"
import {Resource} from "@lib/model/Resource"
import Kontakt from "@intranet/view/kontakt/Kontakt.vue"
import {OrganisationViewModel} from "@intranet/view/organisation/OrganisationViewModel"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Validator} from "@lib/common/validator/Validator"
import {from} from "@lib/common/Functions"

export default defineComponent({
    components: {RepositoryOptions, Kontakt},
    emits: ["update:modelValue", "kontakte", "kontakt-controller", "update:controller", "update:controllerKontakte"],
    props: {
        modelValue: {
            type: Object as PropType<Id<Organisation>>,
            required: true,
        },
        controllerKontakte: {
            type: Object as PropType<KontaktListController<any> | null>,
            required: true,
        },
        createable: Boolean,
        error: String,
        keinDirektkontakt: Boolean,
    },
    setup(props) {
        const organisation = ref<Organisation>()
        const viewModel = computed(() =>
            new OrganisationViewModel(organisation.value ?? from(Organisation, {})),
        )

        function getError(name: string) {
            return Validator.getErrorText(`${Resource.organisation.endpoint}.${name}`)
        }

        async function validate() {
            return Resource.organisation.validate(organisation.value ?? from(Organisation, {}))
        }

        function getOrganisationNameError() {
            const err = getError(OrganisationViewModel.properties.name)
            return `${err} ${err ? ", " : ""}${props.error ?? ""}`.trim()
        }

        return {
            validate,
            getError,
            viewModel,
            Resource,
            organisation: organisation,
            OrganisationViewModel: OrganisationViewModel,
            getOrganisationNameError: getOrganisationNameError,
        }
    },
})
</script>

<style scoped>

</style>