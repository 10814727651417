import {IAnschriftPerson} from "@intranet/view/anschrift/person/IAnschriftPerson"
import {Anschrift, IAnschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Staat} from "@generated/de/lohn24/model/staat/Staat"
import {_props} from "@lib/common/_props"

export class AnschriftPersonViewModel implements IAnschriftPerson {

    static label: Record<keyof IAnschriftPerson, string> = {
        titel: "Titel",
        anrede: "Anrede",
        vorname: "Vorname",
        nachname: "Nachname",
        strasse: "Straße und Hausnummer",
        postleitzahl: "Postleitzahl",
        ort: "Ort",
        land: "Land",
        zusatz: "Zusatz",
    }

    static error(errorText: {
        (property: keyof IAnschrift): string | null | undefined
    }): Record<keyof IAnschriftPerson, string | null | undefined> {
        return {
            get titel() {
                return errorText(_props<IAnschrift>().titel)
            },
            get anrede() {
                return errorText(_props<IAnschrift>().anrede)
            },
            get vorname() {
                return errorText(_props<IAnschrift>().name2)
            },
            get nachname() {
                return errorText(_props<IAnschrift>().name1)
            },
            get strasse() {
                return errorText(_props<IAnschrift>().strasse)
            },
            get postleitzahl() {
                return errorText(_props<IAnschrift>().postleitzahl)
            },
            get ort() {
                return errorText(_props<IAnschrift>().ort)
            },
            get land() {
                return errorText(_props<IAnschrift>().land)
            },
            get zusatz() {
                return errorText(_props<IAnschrift>().zusatz)
            },
        }

    }

    constructor(private anschrift: Anschrift) {
    }

    get titel() {
        return this.anschrift.titel
    }

    set titel(value: string | null) {
        this.anschrift.titel = value?.toNullIfEmpty() ?? null
    }

    get anrede() {
        return this.anschrift.anrede
    }

    set anrede(value: string | null) {
        this.anschrift.anrede = value?.toNullIfEmpty() ?? null
    }

    get vorname() {
        return this.anschrift.name2 ?? ""
    }

    set vorname(value: string) {
        this.anschrift.name2 = value?.toNullIfEmpty() ?? null
    }

    get nachname() {
        return this.anschrift.name1 ?? ""
    }

    set nachname(value: string) {
        this.anschrift.name1 = value?.toNullIfEmpty() ?? ""
    }

    get strasse() {
        return this.anschrift.strasse ?? ""
    }

    set strasse(value: string) {
        this.anschrift.strasse = value?.toNullIfEmpty() ?? ""
    }

    get ort() {
        return this.anschrift.ort ?? ""
    }

    set ort(value: string) {
        this.anschrift.ort = value?.toNullIfEmpty() ?? ""
    }

    get postleitzahl() {
        return this.anschrift.postleitzahl ?? ""
    }

    set postleitzahl(value: string) {
        this.anschrift.postleitzahl = value?.toNullIfEmpty() ?? ""
    }

    get zusatz() {
        return this.anschrift.zusatz ?? ""
    }

    set zusatz(value: string) {
        this.anschrift.zusatz = value?.toNullIfEmpty() ?? ""
    }

    get land() {
        return this.anschrift.land
    }

    set land(value: Id<Staat>) {
        this.anschrift.land = value
    }
}