import {DateTime} from "luxon"
import {LocalDate} from "@lib/common/LocalDate"

export enum Time {
    SECONDS = 1000,
    MIN = 60 * 1000,
    HOUR = 60 * 60 * 1000,
    DAY = 24 * 60 * 60 * 1000,
}

export class LocalMonth {

    dateTime: DateTime

    constructor(private year: number, private month: number) {
        this.dateTime = DateTime.fromObject({
            year: year,
            month: month,
            day: 1,
        })
    }

    get YYYY_MM(): string {
        return this.dateTime.toFormat("yyyy-MM")
    }

    /**
     * formatted as "dd.MM.yyyy"
     */
    get datum(): string {
        return this.dateTime.toFormat("dd.MM.yyyy")
    }

    /**
     * formatted as "MM.yyyy"
     */
    get zeitraum(): string {
        return this.dateTime.toFormat("MM.yyyy")
    }

    static fromString(YYYY_MM: string) {
        const [year, month] = YYYY_MM.split("-")
        return new LocalMonth(parseInt(year), parseInt(month))
    }

    static fromIsoString(isoString: string) {
        const d = DateTime.fromISO(isoString)
        return new LocalMonth(d.year, d.month)
    }

    static fromJSDate(date: Date) {
        return this.fromDateTime(DateTime.fromJSDate(date))
    }

    static fromDateTime(dateTime: DateTime): LocalMonth {
        return new LocalMonth(dateTime.year, dateTime.month)
    }

    static fromLocalDate(datum: LocalDate): LocalMonth {
        return this.fromDateTime(datum.dateTime)
    }

    static now() {
        return LocalMonth.fromJSDate(new Date())
    }

    toString(): string {
        return this.YYYY_MM
    }

    toJSON() {
        return this.toString()
    }

    valueOf(): number {
        return this.dateTime.valueOf()
    }
}

