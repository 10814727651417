<script lang="ts" setup>

import {Validator} from "@lib/common/validator/Validator"
import {nextTick} from "vue"
import {raf} from "@lib/common/Functions"

const props = withDefaults(
    defineProps<{
        label: string
        property: string
        errorProperty?: string
        path: string
        emitValidate?: (path?: string) => void
    }>(),
    {
        emitValidate: () => undefined,
    })

function error(propName: string) {
    return Validator.getErrorText(prop(propName))
}

function prop(key: string): string {
    return `${props.path}.${key}`
}

async function validate(path?: string) {
    await nextTick()
    await raf()
    props.emitValidate(path)
}
</script>

<template>
  <el-form-item
          :error="error(props.errorProperty ?? props.property)"
          :label="props.label"
          v-bind="$attrs"
          @change="validate(props.path)"
  >
    <template #default="ctx">
      <slot :validate="validate" v-bind="ctx"></slot>
    </template>
  </el-form-item>
</template>

<style lang="scss" scoped>

</style>