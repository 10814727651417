<template>
  <el-table
          v-if="tableData.length > 0"
          :cell-class-name="cellClassName"
          :data="tableData"
          :row-class-name="rowClassName"
          data-cy="freitextartikel-table"
  >
    <el-table-column :width="40">
      <template #default="{row}">
        <el-button size="large" text @click="controller.removeFreitextArtikel(row)">
          <icon-delete />
        </el-button>
      </template>
    </el-table-column>
    <el-table-column :width="300" label="Freitextartikel">
      <template #default="{row}">
        <validateable-input
                ref="input"
                v-model="row.text"
                :error="error(row, FreitextBelegPositionViewModel.properties.text)"
                immediate
                size="small"
                @validate="validate()"
        ></validateable-input>
      </template>
    </el-table-column>
    <el-table-column :property="FreitextBelegPositionViewModel.properties.notiz" :width="260" label="Notiz">
      <template #default="{row}">
        <validateable-input
                v-model="row.notiz"
                :error="error(row, FreitextBelegPositionViewModel.properties.notiz)"
                :name="FreitextBelegPositionViewModel.properties.notiz"
                size="small"
                @validate="validate()"
        ></validateable-input>
      </template>
    </el-table-column>

    <el-table-column />
    <el-table-column :width="150" align="right" fixed="right" label="Rechnung">
      <template #default="{row}">
        <validateable-input
                v-model="row.menge"
                :error="error(row, FreitextBelegPositionViewModel.properties.menge)"
                :min="0"
                :step="1"
                size="small"
                type="input-number"
                @validate="validate()"
        />
      </template>
    </el-table-column>
    <el-table-column :width="150" align="right" fixed="right" label="Netto">
      <template #default="{row}">
        <validateable-input
                v-model="row.netto"
                :error="error(row, FreitextBelegPositionViewModel.properties.netto)"
                :step="0.01"
                class="text-align-right"
                hide-error-text
                size="small"
                type="input-number"
                @validate="validate()"
        ></validateable-input>
      </template>
    </el-table-column>
    <el-table-column
            :prop="FreitextBelegPositionViewModel.properties.summe"
            align="right"
            fixed="right"
            label="Summe"
            width="80"
    />
  </el-table>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"
import {Icons} from "@lib/view/icons"
import {ValidateableInput} from "@lib/view"
import {BelegBuilderController} from "@intranet/view/rechnung/builder/BelegBuilderController"
import {FreitextBelegPositionViewModel} from "@intranet/view/rechnung/builder/FreitextBelegPositionViewModel"
import {Validator} from "@lib/common/validator/Validator"
import {_props} from "@lib/common/_props"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {ValidateableListItem} from "@lib/common/validator/ValidateableListItem"
import {ValidationError} from "@lib/common/axios/AxiosError"

export default defineComponent({
    name: "FreitextArtikelPositionTable",
    components: {
        ValidateableInput,
        IconDelete: Icons.IconDelete,
    },
    props: {
        controller: {
            type: Object as PropType<BelegBuilderController>,
            required: true,
        },
        manuell: Boolean,
        preis: Boolean,
    },
    setup(props) {

        const tableData = computed<FreitextBelegPositionViewModel[]>(() => {
            return props.controller.freitextArtikelPositionen.value
                .map((it, index) => {
                    it.validationIndex = index
                    return it
                })
                .filter(it => !it.bearbeitungszustand.deleted)
        })

        type CellClassNameArgument = {
            row: FreitextBelegPositionViewModel,
            column: { property: string },
            rowIndex,
            columnIndex
        }

        function cellClassName({column}: CellClassNameArgument) {
            const classNames: string[] = []

            if (column.property === FreitextBelegPositionViewModel.properties.text)
                classNames.push("no-box-shadow")
            if (column.property === FreitextBelegPositionViewModel.properties.notiz)
                classNames.push("no-box-shadow")
            return classNames.join(" ")
        }

        function rowClassName({row}: { row: FreitextBelegPositionViewModel, rowIndex: number }) {
            const classNames: string[] = []
            if (row.bearbeitungszustand.deleted)
                classNames.push("row-deleted")
            return classNames.join(" ")
        }

        function error(row: ValidateableListItem, name: string): string {
            const index = row.validationIndex
            return Validator.getErrorText(
                `${props.controller.validatorIdentifier}.${_props<BelegBuilder>().freitextArtikelPositionen}.${index}.${name}`,
            )
        }

        async function validate() {
            try {
                await props.controller.validate()
            } catch (e) {
                if (e instanceof ValidationError) {
                    // do nothing
                } else {
                    throw e
                }
            }

        }

        return {
            validate,
            error,
            FreitextBelegPositionViewModel,
            rowClassName,
            cellClassName,
            Number,
            tableData,
        }
    },
})
</script>

<style lang="scss">
.text-align-right {
  input {
    text-align: right
  }
}
</style>