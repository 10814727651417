import {KontaktViewModel} from "@intranet/view/kontakt/KontaktViewModel"
import {ListController} from "@lib/common/controller/ListController"
import {computed, ref} from "vue"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Model} from "@lib/common/model/Model"
import {Id} from "@lib/common/model/Id"
import {Validator} from "@lib/common/validator/Validator"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"
import {ValidateableList} from "@lib/common/validator/ValidateableListItem"
import {from} from "@lib/common/Functions"
import {UuidService} from "@lib/common/uuid/UuidService"

export class KontaktListController<L extends Model<L>> extends ListController<KontaktViewModel> {
    dataSaveFailedMessage = "Kontaktdaten"
    dataSavedSuccessfullyMessage = "Kontaktdaten"
    rowToValidate = ref<KontaktViewModel>()
    errors = ref<Record<number, Record<keyof Kontakt, string>>>({})
    protected override modifiedValues = computed(() => this.allValues.filter(it => it.changed || it.bearbeitungszustand.deleted))

    constructor(private relation: RepositoryRelation<L, Kontakt>, public modelId: Id<L>) {
        super()
    }

    override get modified(): boolean {
        return this.allValues.any(it => it.changed || it.bearbeitungszustand.deleted)
    }

    override get data() {
        return this.allValues
            .filter(it => !it.bearbeitungszustand.deleted)
            .sort((a, b) => this.sort(a, b))

    }

    sort(a: KontaktViewModel, b: KontaktViewModel) {
        return b.position - a.position
    }

    override addRow(row: KontaktViewModel) {
        row.kontakt.order = this.allValues.reduce((max, item) => Math.max(max, item.kontakt.order), 0) + 1
        super.addRow(row)
    }

    async create() {
        const viewModel = new KontaktViewModel(from(Kontakt, {
            id: UuidService.v7(),
        }))
        viewModel.bearbeitungszustand.flagCreated().flagEdited()
        viewModel.validationIndex = ValidateableList.nextIndex(this.allValues)
        this.addRow(viewModel)
    }

    protected async fetchData(): Promise<KontaktViewModel[]> {
        if (this.modelId?.value !== null) {
            const kontakte = await this.relation.all(Id.required(this.modelId))
            return kontakte.map((it, index) => {
                const viewModel = new KontaktViewModel(it)
                viewModel.validationIndex = index
                return viewModel
            })
        }
        return []
    }

    protected override async updateData(data: KontaktViewModel[]): Promise<KontaktViewModel[]> {
        const rtn: KontaktViewModel[] = []
        for (const viewModel of data) {
            const index = data.findIndex(item => item.kontakt.id?.value == viewModel.kontakt.id?.value)
            if (await this.deleteViewModel(viewModel)) {
                this.removeValue(viewModel)
            } else {
                const changedViewModel = await this.createViewModel(viewModel, index) ?? await this.updateViewModel(viewModel, index)
                if (changedViewModel) {
                    rtn.push(changedViewModel)
                    this.updateOrInsertValue(changedViewModel)
                }
            }
        }
        return rtn
    }

    private async deleteViewModel(viewModel: KontaktViewModel) {
        if (viewModel.bearbeitungszustand.deleted) {
            await this.relation.delete(this.modelId, viewModel.kontakt)
            return viewModel.kontakt.id
        }
        return null
    }

    private async createViewModel(viewModel: KontaktViewModel, index: number): Promise<Nullable<KontaktViewModel>> {
        if (viewModel.bearbeitungszustand.created) {
            const error = await Validator.validate("kontakt", viewModel.kontakt, `kontakt${index}`)
            if (error)
                return null
            const kontakt = await this.relation.create(Id.required(this.modelId), viewModel.kontakt)
            return new KontaktViewModel(kontakt)
        }
        return null
    }

    private async updateViewModel(viewModel: KontaktViewModel, index: number): Promise<Nullable<KontaktViewModel>> {
        if (viewModel.changed) {
            const error = await Validator.validate("kontakt", viewModel.kontakt, `kontakt${index}`)
            if (error)
                return null
            const kontakt = await this.relation.update(this.modelId, viewModel.kontakt)
            return new KontaktViewModel(kontakt)
        }
        return null
    }

}