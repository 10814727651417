<script lang="ts" setup>
import {ref, watch} from "vue"
import {MandantEditDialogController} from "@intranet/view/mandant/edit/MandantEditDialogController"
import EditDialog from "@lib/view/editdialog/EditDialog.vue"
import MandantStammdaten from "@intranet/view/mandant/stammdaten/MandantStammdaten.vue"
import {Resource} from "@lib/model/Resource"
import MandantLohnabrechnung from "@intranet/view/mandant/component/MandantLohnabrechnung.vue"
import Organisation from "@intranet/view/organisation/Organisation.vue"
import MandantBenachrichtigungsoption from "@intranet/view/mandant/benachrichtigung/MandantBenachrichtigungsoption.vue"
import MandantArtikel from "@intranet/view/mandant/artikel/MandantArtikel.vue"
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import DebitorStammdaten from "@intranet/view/debitor/DebitorStammdaten.vue"
import Relation from "@lib/view/relation/Relation.vue"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {RelationDefaultTemplateContext} from "@lib/view/relation/RelationDefaultTemplateContext"
import {ValidationService} from "@lib/common/validator/ValidationService"

const props = withDefaults(
    defineProps<ComponentRendererProps<Mandant, MandantEditDialogController>>(),
    {
        onVanish: ComponentRenderer.onVanishDefault,
        __loader: ComponentRenderer.loaderDefault,
    },
)
const validationService = new ValidationService(props.controller.validator.value)
const activeTab = ref(props.controller.tabs.first().name)

async function save() {
    await props.controller.save()
    props.done(props.controller.mandant)
}

watch(activeTab, async () => {
    await validationService.validate()
})

</script>

<template>
  <edit-dialog
          v-model="activeTab"
          :controller="props.controller"
          :validator="props.controller.validator.value"
          @save="save"
  >
    <template #stammdaten-content>
      <mandant-stammdaten
              v-model:controller-anschrift="props.controller.anschriftController.value"
              :mandant="props.controller.mandant"
              path-anschrift="rechnungsanschrift"
              @validate="validationService.validate($event)"
      />
    </template>
    <template #debitor-content>
      <relation
              v-model:controller="props.controller.debitorController.value"
              :build-controller-instance="DebitorController.build"
              :parent="props.controller.viewModel.mandant.id"
              :relation="Resource.mandant.debitor"
      >
        <template #default="{controller}: RelationDefaultTemplateContext<DebitorController<Mandant>>">
          <debitor-stammdaten
                  :abrechnungssystem="props.controller.mandant.abrechnungssystem"
                  :controller="controller"
                  :rechnungssteller="!props.controller.viewModel.mandant.debitor"
                  path="debitor"
                  @validate="validationService.validate($event)"
                  :rechnungsanschrift="false"
          />
        </template>
      </relation>

    </template>
    <template #lohnabrechnung-content>
      <mandant-lohnabrechnung
              :mandant="props.controller.viewModel.mandant"
              @validate="validationService.validate($event)"
      />
    </template>
    <template #organisation-content>
      <organisation
              v-model="props.controller.viewModel.organisation"
              v-model:controller-kontakte="props.controller.organisationKontaktController.value"
              :kein-direktkontakt="props.controller.viewModel.istKeinDirektkontakt()"
      />
    </template>
    <template #benachrichtigungsoption-content>
      <mandant-benachrichtigungsoption
              :key="props.controller.viewModel.mandant.id"
              v-model:controller="props.controller.benachrichtigungoptionController.value"
              v-model:controller-kontakte="props.controller.organisationKontaktController.value"
              v-model:controller-kooperationspartner-kontakte="props.controller.mandantKooperationspartnerKontaktController.value"
              :mandant="props.controller.viewModel.mandant"
              :sammelrechnung="props.controller.viewModel.istSammelrechnung()"
      />
    </template>
    <template #artikel-content>
      <mandant-artikel
              :id="props.controller.viewModel.mandant.id"
              v-model:artikelkatalog="props.controller.artikelkatalog"
              v-model:controller="props.controller.mandantArtikelController.value"
      />
    </template>
  </edit-dialog>
</template>

<style>
.el-message__multiline {
    white-space: pre-line;
}

.el-message__multiline p {
    padding: 2px;
    cursor: default;
}
</style>
