import {IAnschriftPostfach} from "@intranet/view/anschrift/postfach/IAnschriftPostfach"
import {Anschrift, IAnschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Staat} from "@generated/de/lohn24/model/staat/Staat"
import {_props} from "@lib/common/_props"

export class AnschriftPostfachViewModel implements IAnschriftPostfach {
    static label: Record<keyof IAnschriftPostfach, string> = {
        name: "Name",
        strasse: "Straße und Hausnummer",
        postleitzahl: "Postleitzahl",
        ort: "Ort",
        land: "Land",
        zusatz: "Zusatz",
    }

    static error(errorText: {
        (property: keyof IAnschrift): string | null | undefined
    }): Record<keyof IAnschriftPostfach, string | null | undefined> {
        return {
            get name() {
                return errorText(_props<IAnschrift>().name1)
            },
            get strasse() {
                return errorText(_props<IAnschrift>().strasse)
            },
            get postleitzahl() {
                return errorText(_props<IAnschrift>().postleitzahl)
            },
            get ort() {
                return errorText(_props<IAnschrift>().ort)
            },
            get land() {
                return errorText(_props<IAnschrift>().land)
            },
            get zusatz() {
                return errorText(_props<IAnschrift>().zusatz)
            },
        }

    }

    constructor(private anschrift: Anschrift) {
    }

    get name() {
        return this.anschrift.name1 ?? ""
    }

    set name(value: string) {
        this.anschrift.name1 = value?.toNullIfEmpty() ?? ""
    }

    get strasse() {
        return this.anschrift.strasse ?? ""
    }

    set strasse(value: string) {
        this.anschrift.strasse = value?.toNullIfEmpty() ?? ""
    }

    get ort() {
        return this.anschrift.ort ?? ""
    }

    set ort(value: string) {
        this.anschrift.ort = value?.toNullIfEmpty() ?? ""
    }

    get postleitzahl() {
        return this.anschrift.postleitzahl ?? ""
    }

    set postleitzahl(value: string) {
        this.anschrift.postleitzahl = value?.toNullIfEmpty() ?? ""
    }

    get zusatz() {
        return this.anschrift.zusatz ?? ""
    }

    set zusatz(value: string) {
        this.anschrift.zusatz = value?.toNullIfEmpty() ?? ""
    }

    get land() {
        return this.anschrift.land
    }

    set land(value: Id<Staat>) {
        this.anschrift.land = value
    }
}