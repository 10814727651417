<script setup lang="ts">

import {computed} from "vue"
import {MandantOrganisationViewModel} from "@intranet/view/mandant/organisation/MandantOrganisationViewModel"
import {ContextMenuRowContext} from "@lib/common/contextmenu/ContextMenuBuilder"
import {MandantOrganisationContextMenu} from "@intranet/view/mandant/organisation/MandantOrganisationContextMenu"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"

const props = defineProps<{
    mandanten: Mandant[]
}>()
const data = computed(() => props
    .mandanten
    .map(it => new MandantOrganisationViewModel(it))
    // .filter(it => it.mandant.status !== MandantStatus.INAKTIV)
    .sortBy(it => it.kennung),
)

function rowClassName({row}: { row: MandantOrganisationViewModel, rowIndex: number }): string {
    const classNames: string[] = ["test"]
    if (row.status === MandantStatus.INAKTIV)
        classNames.push("status-inaktiv")
    return classNames.join(" ")
}

function openContextMenu(context: ContextMenuRowContext<MandantOrganisationViewModel>) {
    new MandantOrganisationContextMenu(context)
}

</script>

<template>
  <el-row class="mandant-organisation">
    <el-col>
      <el-row>
        <el-col>
          <h3>Organisation</h3>
        </el-col>
        <el-col></el-col>
      </el-row>
      <el-table
              class="organisation-table"
              :data="data"
              :row-class-name="rowClassName"
              size="small"
              stripe
              @row-contextmenu="(row, col, event) => openContextMenu({
                event: event,
                row: row,
                column: col,
                route: $route,
                router: $router
              })"
      >
        <el-table-column
                :label="MandantOrganisationViewModel.label.kennung"
                :prop="MandantOrganisationViewModel.properties.kennung"
                :width="80"
        />
        <el-table-column
                :label="MandantOrganisationViewModel.label.name"
                :prop="MandantOrganisationViewModel.properties.name"
        />
        <el-table-column
                :label="MandantOrganisationViewModel.label.abrechnungszeitraum"
                :prop="MandantOrganisationViewModel.properties.abrechnungszeitraum"
                :width="150"
        />
      </el-table>
    </el-col>
  </el-row>
</template>

<style>
.organisation-table {
  .status-inaktiv {
      color: var(--l24-color-table-text-inactive);
      background: var(--l24-color-table-bg-inactive);
  }
}
</style>