<script lang="ts" setup>
import {Component, computed, nextTick, ref, watch} from "vue"
import {assertIsDefined, assertNotNull} from "@lib/common/Functions"
import ContextMenu from "@lib/view/contextmenu/ContextMenu.vue"

const emits = defineEmits<{
    "update:active": [active?: HTMLElement]
    "close": []
}>()
const props = withDefaults(
    defineProps<{
        label: string
        left?: boolean
        dx?: number
        enabled?: boolean
        active?: HTMLElement
        onClose?: () => void
        icon?: Component
    }>(),
    {
        dx: 0,
    },
)

const li = ref<HTMLElement>()
const childLi = ref()
const rect = ref<DOMRect>()
const parentRect = ref<DOMRect>()
const submenu = ref()
const localActive = ref<boolean>(false)
const paddingTop = -5
const openLeft = ref<boolean>(false)
const submenuPos = ref<{ left: number, top: number }>({left: 0, top: 0})
const isActive = computed(() => {
    return localActive.value && props.active === li.value
})
const liClassNames = computed(() => {
    const names: string[] = ["context-sub-menu context-menu-item"]
    if (!props.enabled) {
        names.push("inactive")
    }
    return names.join(" ")
})
const labelClassNames = computed(() => {
    const names: string[] = ["context-sub-menu__label"]
    if (!props.enabled) {
        names.push("inactive")
    }
    return names.join(" ")
})

watch(isActive, value => {
    if (!value)
        localActive.value = false
})

watch(li, async (value?: HTMLElement) => {
    if (!value) return
    // warten bis alles gerendert ist, sonst stimmen die Rects nicht
    await nextTick()
    const vw = Math.max(document.documentElement?.clientWidth || 0, window.innerWidth || 0)
    rect.value = value.getBoundingClientRect()
    parentRect.value = assertNotNull(value.parentElement).getBoundingClientRect()
    openLeft.value = (rect.value?.width || 0) + (rect.value?.right || 0) > vw
    // openTop.value = ()
    if (openLeft.value || props.left)
        submenuPos.value.left = -(rect.value?.width || 0) + 6
    else
        submenuPos.value.left = (rect.value?.width || 0) - 6
    submenuPos.value.top = (rect.value?.top - parentRect.value.top) - paddingTop
})

watch(localActive, (value: boolean) => {
    if (value)
        emits("update:active", li.value)
})

function onmouseleave(ev: MouseEvent): void {
    assertIsDefined(rect.value)
    const left = ev.x <= rect.value.x
    const top = ev.y <= rect.value.y
    const right = ev.x >= rect.value.x + rect.value.width
    const bottom = ev.y >= rect.value.y + rect.value.height
    const side = (openLeft.value || props.left) ? right : left
    if (bottom || top || side)
        localActive.value = false
}

watch(submenu, async (value) => {
    if (value) {
        const vh = Math.max(document.documentElement?.clientHeight || 0, window.innerHeight || 0)
        await nextTick()
        const el = assertNotNull(value.$el) as HTMLDivElement
        const submenuRect = el.getBoundingClientRect()
        const firstChildRect = (el.firstChild as HTMLDivElement).getBoundingClientRect()
        assertIsDefined(rect.value)
        assertIsDefined(parentRect.value)
        if (submenuRect.x + rect.value.height > vh) {
            submenuPos.value.top = (rect.value.top - parentRect.value.top) - paddingTop - firstChildRect.height + rect.value.height
        }
    }
})

</script>

<template>
  <li ref="li"
      :class="liClassNames"
      @mouseenter="localActive=props.enabled"
      @mouseleave="onmouseleave"
  >
    <div class="row">
      <div class="col">
        <span clasS="icon-prepend">
          <slot name="icon">
            <component :is="props.icon" v-if="props.icon" />
          </slot>
        </span>
        <span :class="labelClassNames">{{ props.label }}</span>
      </div>
      <div class="col">
        <span>
          <i class="icon">
            <svg
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
            >
              <path
                      d="M340.864 149.312a30.592 30.592 0 0 0 0 42.752L652.736 512 340.864 831.872a30.592 30.592 0 0 0 0 42.752 29.12 29.12 0 0 0 41.728 0L714.24 534.336a32 32 0 0 0 0-44.672L382.592 149.376a29.12 29.12 0 0 0-41.728 0z"
                      fill="currentColor"
              />
            </svg>
          </i>
        </span>

      </div>
    </div>
  </li>
  <transition mode="in-out">
    <context-menu
            v-if="isActive"
            ref="submenu"
            :overlay="false"
            :style="`position: absolute; left: ${submenuPos.left + dx}px; top: ${submenuPos.top}px`"
    >
      <template #default="context">
        <slot
                :active="childLi"
                :dx="context.dx"
                :enabled="context.enabled && isActive"
                :left="context.left || left || openLeft"
                @close="$emit('close')"
                @update:active="$emit('update:active', li);childLi=$event"
        />
      </template>
    </context-menu>
  </transition>
</template>

<style lang="scss">
.context-sub-menu {
  &.inactive {
    //background-color: var(--el-disabled-bg-color);
    color: var(--el-disabled-text-color);
    cursor: not-allowed;
  }

  .row {
    display: flex;
    justify-content: space-between;

  }

  .icon {
    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: var(--color);
    font-size: inherit;
  }

  span.context-sub-menu__label {
    //width: calc(80%); // TODO: makes it weird
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>