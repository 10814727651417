<script generic="C extends DialogController" lang="ts" setup>

import {DialogController} from "@lib/common/controller/DialogController"
import BadgedName from "@lib/view/BadgedLabel.vue"
import Debounced from "@lib/view/Debounced.vue"
import {Validator} from "@lib/common/validator/Validator"
import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {computed, ref, watch} from "vue"
import {ElForm} from "element-plus"
import CustomDialogV2 from "@lib/view/dialog/CustomDialogV2.vue"

const props = withDefaults(
    defineProps<{
        modelValue: string,
        controller: C & { tabs: EditDialogTab[] }
        labelWidth?: number | string
        validator?: Validator
        noTabHeader?: boolean
    }>(),
    {
        labelWidth: "150px",
        noTabHeader: false,
    },
)
const emits = defineEmits<{
    "update:modelValue": [activeTab: string]
    "save": []
}>()
const form = ref<InstanceType<typeof ElForm>>()
const activeTab = computed<string>({
    get() {
        return props.modelValue
    },
    set(value: string) {
        emits("update:modelValue", value)
    },
})
const badgeSum = computed(() => props.controller.tabs.sumBy(it => it.errorCount()))
const modified = ref<boolean>(false)

async function save() {
    if (await form.value?.validate()) {
        emits("save")
    }
}

watch(() => props.controller.modified.bind(props.controller)(), value => {
    modified.value = value
}, {immediate: true})

props.controller.useController()
</script>

<template>
  <custom-dialog-v2
          v-model="props.controller.visible.value"
          :close="() => props.controller.close()"
          :title="props.controller.title.value"
          intent="edit"
  >
    <template #header-right>
      <slot name="header-right"></slot>
    </template>
    <template #sub-header-left>
      <slot name="sub-header-left"></slot>
    </template>
    <template #sub-header-right>
      <slot name="sub-header-right"></slot>
    </template>
    <template #default>
      <el-form
              ref="form"
              :label-width="props.labelWidth"
              :model="props.validator?.ruleModel.value"
              :rules="props.validator?.rules.value"
              :validate-on-rule-change="false"
              label-position="left"
              size="small"
      >
        <el-tabs v-model="activeTab"
                 :class="{'no-header': noTabHeader}"
                 :stretch="true"
                 tab-position="left"
        >
          <div>
            <slot :active="activeTab" name="tab-panes">
              <slot v-for="tab of props.controller.tabs.filter(it => it.available())" :key="tab.name" :name="tab.name">
                <el-tab-pane :disabled="tab.disabled()" :name="tab.name">
                  <template #label>
                    <badged-name
                            :label="tab.label"
                            :value="tab.disabled() ? 0 : tab.errorCount()"
                    />
                  </template>
                  <slot v-if="!tab.disabled() && activeTab === tab.name"
                        :name="tab.name + '-content'"
                        :tab="tab"
                  ></slot>
                </el-tab-pane>
              </slot>
            </slot>
          </div>
        </el-tabs>
      </el-form>
    </template>
    <template #footer>
      <el-button
              data-cy="cancel"
              name="cancel"
              @click="() => props.controller.close()"
      >
        Abbrechen
      </el-button>
      <debounced :wait="600">
        <template #default="{debounce}">
          <el-button
                  :disabled="!modified|| badgeSum > 0"
                  data-cy="save"
                  name="save"
                  type="primary"
                  @click="debounce(save)"
          >
            Speichern
          </el-button>
        </template>
      </debounced>
    </template>
  </custom-dialog-v2>
</template>

<style lang="scss">
.edit-dialog__wrapper {
  .no-header {
    .el-tabs__header {
      display: none;
    }
  }

  .el-tabs {
    .el-tabs__item.is-left {
      text-align: left;
      padding-left: 0;
    }

    .el-tabs__header.is-left {
      margin-right: 20px;
    }
  }
}
</style>