import {ViewModel} from "@lib/common/model/ViewModel"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {_props} from "@lib/common/_props"
import {Id} from "@lib/common/model/Id"
import {RecordOf} from "@lib/types"
import {from} from "@lib/common/Functions"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {ValidateableListItem} from "@lib/common/validator/ValidateableListItem"
import {MandantArtikelBuilder} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikelBuilder"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Resource} from "@lib/model/Resource"

export class MandantArtikelViewModel extends ViewModel<MandantArtikelViewModel> implements ValidateableListItem {
    static propertyNames = _props<InstanceType<typeof this>>()
    static label: RecordOf<MandantArtikelViewModel> = {
        name: "Bezeichnung",
        netto: "Netto",
        vorgabeMengeRechnung: "Vorgabemenge Rechnung",
    }
    validationIndex: number = 0
    artikel: Artikel | null = null
    artikelkatalogArtikel: ArtikelkatalogArtikel | null = null
    readonly mandantArtikel: MandantArtikel

    constructor(artikelkatalogArtikel: ArtikelkatalogArtikel | null, artikel: Artikel | null, mandantArtikel: MandantArtikel = from(MandantArtikel, {})) {
        super()
        this.artikel = artikel
        this.artikelkatalogArtikel = artikelkatalogArtikel
        this.mandantArtikel = this.registerShallowReactive(mandantArtikel)
    }

    get mandantArtikelBuilder(): MandantArtikelBuilder {
        return new MandantArtikelBuilder({
            id: this.mandantArtikel.id.value,
            artikel: this.mandantArtikel.artikel.value,
            mandant: this.mandantArtikel.artikel.value,
            name: this.mandantArtikel.name,
            netto: this.mandantArtikel.netto,
            sachbearbeiterabschluss: this.mandantArtikel.sachbearbeiterabschluss,
            rechnungsabschluss: this.mandantArtikel.rechnungsabschluss,
            vorgabeMengeRechnung: this.mandantArtikel.vorgabeMengeRechnung,
        })
    }

    get name() {
        return this.mandantArtikel.name ?? ""
    }

    set name(value: string) {
        this.mandantArtikel.name = value
    }

    get artikelName() {
        return this.artikel?.name
    }

    get netto(): Nullable<number> {
        return this.mandantArtikel.netto
    }

    set netto(value: Nullable<number>) {
        this.mandantArtikel.netto = value
    }

    get artikelNetto() {
        return this.artikelkatalogArtikel?.netto.toFixed(2).replace(".", ",")
    }

    get vorgabeMengeRechnung(): Nullable<number> {
        return this.mandantArtikel.vorgabeMengeRechnung ?? null
    }

    set vorgabeMengeRechnung(value: Nullable<number>) {
        this.mandantArtikel.vorgabeMengeRechnung = value
    }

    get umsatzsteuersatz() {
        return this.artikelkatalogArtikel?.umsatzsteuersatz
    }

    get artikelVorgabeMengeRechnung(): string {
        return this.artikelkatalogArtikel?.vorgabeMengeRechnung.toFixed(2).replace(".", ",") ?? "error"
    }

    get artikelId(): Id<ArtikelkatalogArtikel> | null {
        return this.mandantArtikel.artikel ?? null
    }

    set artikelId(id: Id<ArtikelkatalogArtikel>) {
        this.mandantArtikel.artikel = id;
        (async () => {
            const artikelkatalogArtikel = Resource.artikelkatalogArtikel.firstOrNull(id)
            const artikel = Resource.artikelkatalogArtikel.artikel.firstOrNull(id)
            this.artikelkatalogArtikel = await artikelkatalogArtikel
            this.artikel = await artikel
        })()
    }

    get sachbearbeiterabschluss(): Nullable<boolean> {
        return this.mandantArtikel.sachbearbeiterabschluss
    }

    set sachbearbeiterabschluss(value: Nullable<boolean>) {
        this.mandantArtikel.sachbearbeiterabschluss = value
    }

    get rechnungsabschluss(): Nullable<boolean> {
        return this.mandantArtikel.rechnungsabschluss
    }

    set rechnungsabschluss(value: Nullable<boolean>) {
        this.mandantArtikel.rechnungsabschluss = value
    }

    override get changed() {
        return super.changed || this.bearbeitungszustand.deleted
    }

    override equals(other: ViewModel<MandantArtikelViewModel>): boolean {
        return other instanceof MandantArtikelViewModel
            && other.artikelId?.value === this.artikelId?.value
            && other.mandantArtikel.id?.value === this.mandantArtikel.id?.value
            && other.validationIndex === this.validationIndex

    }
}