<script lang="ts" setup>
import {computed, ref, watch} from "vue"
import {
    KooperationspartnerStammdatenViewModel,
} from "@intranet/view/kooperationspartner/stammdaten/KooperationspartnerStammdatenViewModel"
import {Id} from "@lib/common/model/Id"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {Validator} from "@lib/common/validator/Validator"
import {AnschriftController} from "@intranet/view/anschrift/old/AnschriftController"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import AnschriftForm from "@intranet/view/anschrift/old/AnschriftForm.vue"
import KooperationspartnerOptions from "@intranet/view/kooperationspartner/KooperationspartnerOptions.vue"
import Relation from "@lib/view/relation/Relation.vue"
import {RelationDefaultTemplateContext} from "@lib/view/relation/RelationDefaultTemplateContext"
import {KooperationspartnerModelController} from "@intranet/view/kooperationspartner/KooperationspartnerModelController"

const props = withDefaults(
    defineProps<{
        controller: KooperationspartnerModelController
        controllerAnschrift: AnschriftController<Kooperationspartner> | null
        path?: string
        pathAnschrift?: string
    }>(),
    {
        path: "kooperationspartner",
        pathAnschrift: "anschrift",
    },
)

const emits = defineEmits<{
    "update:controllerAnschrift": [controller: AnschriftController<Kooperationspartner> | null]
    "validate": [path?: string]
}>()

const viewModel = computed<KooperationspartnerStammdatenViewModel>(() => {
    const kooperationspartner = props.controller.model.value.kooperationspartner
    return new KooperationspartnerStammdatenViewModel(kooperationspartner)
})
const sammelrechnungArtikelKatalog = ref<Id<Artikelkatalog> | null>(null)

function updateArtikelKatalog(artikelKatalog: Id<Artikelkatalog> | null, kooperationspartner: KooperationspartnerStammdatenViewModel) {
    kooperationspartner.artikelkatalog = artikelKatalog
    if (artikelKatalog?.value) {
        kooperationspartner.sammelrechnung = null
        kooperationspartner.keineRechnung = false
    }
}

async function updateSammelrechnung(sammelrechnungId: Id<Kooperationspartner> | null, kooperationspartner: KooperationspartnerStammdatenViewModel) {
    kooperationspartner.sammelrechnung = sammelrechnungId
    if (sammelrechnungId?.value) {
        kooperationspartner.artikelkatalog = null
        kooperationspartner.keineRechnung = false
        const sammelrechnungKooperationspartner = await ResourceCache.kooperationspartner.get(sammelrechnungId)
        sammelrechnungArtikelKatalog.value = sammelrechnungKooperationspartner.artikelkatalog
    }
}

function prop(property: string): string {
    return `${props.path}.${property}`
}

function error(property: string) {
    return Validator.getErrorText(prop(property))
}

watch(() => viewModel.value.kooperationspartner.sammelrechnung, async sammelrechnungId => {
    if (sammelrechnungId?.value) {
        viewModel.value.kooperationspartner.artikelkatalog = null
        viewModel.value.kooperationspartner.keineRechnung = false
        const sammelrechnungKoop = await Resource.kooperationspartner.get(`${sammelrechnungId}`)
        sammelrechnungArtikelKatalog.value = sammelrechnungKoop.artikelkatalog
    } else {
        sammelrechnungArtikelKatalog.value = null
    }
}, {immediate: true})

</script>

<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <h3>Stammdaten</h3>
      <el-form-item
              :error="error(KooperationspartnerStammdatenViewModel.props.name)"
              :label="KooperationspartnerStammdatenViewModel.label.name"
              :prop="prop(KooperationspartnerStammdatenViewModel.props.name)"
      >
        <el-input
                v-model="viewModel.name"
                name="name"
        ></el-input>
      </el-form-item>
      <el-form-item :error="error(KooperationspartnerStammdatenViewModel.props.kennung)"
                    :label="KooperationspartnerStammdatenViewModel.label.kennung"
                    :prop="prop(KooperationspartnerStammdatenViewModel.props.kennung)"
      >
        <el-input
                v-model="viewModel.kennung"
                name="kennung"
                type="number"
        ></el-input>
      </el-form-item>
      <el-form-item :prop="prop(KooperationspartnerStammdatenViewModel.props.sammelrechnungFestschreiben)"
                    label="Stichtag festschreiben"
      >
        <el-input
                v-model="viewModel.sammelrechnungFestschreiben"
                max="31"
                min="1"
                type="number"
        ></el-input>
      </el-form-item>
      <el-form-item
              :error="error(KooperationspartnerStammdatenViewModel.props.sammelrechnung)"
              :label="KooperationspartnerStammdatenViewModel.label.sammelrechnung"
              :prop="prop(KooperationspartnerStammdatenViewModel.props.sammelrechnung)"
      >
        <kooperationspartner-options
                v-model="viewModel.sammelrechnung"
                :keine-rechnung="false"
                :keine-sammelrechnung="false"
                tooltip="Die eingetragene Sammelrechung wird an dem Mandanten bei auswahl dieses Kooperationspartners gesetzt"
        >
        </kooperationspartner-options>
      </el-form-item>
      <el-form-item
              :error="error(KooperationspartnerStammdatenViewModel.props.artikelkatalog)"
              :label="`Artikelkatalog ${viewModel.sammelrechnung ? 'der Sammelrechnung' : ''}`"
              :prop="prop(KooperationspartnerStammdatenViewModel.props.artikelkatalog)"
              style="word-break: keep-all"
      >
        <repository-options
                :disabled="viewModel.artikelkatalogDisabled"
                :model-value="viewModel.artikelkatalog !== null ? viewModel.artikelkatalog : (sammelrechnungArtikelKatalog as Id<Artikelkatalog>)"
                :repository="Resource.artikelkatalog"
                @update:modelValue="updateArtikelKatalog($event, viewModel)"
        >
        </repository-options>
      </el-form-item>
      <el-form-item
              :error="error(KooperationspartnerStammdatenViewModel.props.keineRechnung)"
              :prop="prop(KooperationspartnerStammdatenViewModel.props.keineRechnung)"
      >
        <el-checkbox
                v-model="viewModel.keineRechnung"
                :label="KooperationspartnerStammdatenViewModel.label.keineRechnung"
        >
        </el-checkbox>
      </el-form-item>
      <el-form-item
              :error="error(KooperationspartnerStammdatenViewModel.props.artikeltextAusfuehrlich)"
              :prop="prop(KooperationspartnerStammdatenViewModel.props.artikeltextAusfuehrlich)"
      >
        <el-checkbox
                v-model="viewModel.artikeltextAusfuehrlich"
                :label="KooperationspartnerStammdatenViewModel.label.artikeltextAusfuehrlich"
        >
        </el-checkbox>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <relation
              :build-controller-instance="AnschriftController.build"
              :controller="props.controllerAnschrift"
              :relation="Resource.kooperationspartner.anschrift"
              :parent="viewModel.id"
              @update:controller="emits('update:controllerAnschrift', $event)"
      >
        <template #default="{controller}: RelationDefaultTemplateContext<AnschriftController<Kooperationspartner>>">
          <anschrift-form
                  :controller="controller"
                  :path="pathAnschrift"
                  @validate="emits('validate', undefined)"
          />
        </template>
      </relation>
    </el-col>
  </el-row>
</template>

<style scoped>

</style>