<script setup lang="ts">

import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {capitalize} from "lodash"
import AnschriftPerson from "@intranet/view/anschrift/person/AnschriftPerson.vue"
import AnschriftUnternehmen from "@intranet/view/anschrift/unternehmen/AnschriftUnternehmen.vue"
import AnschriftPostfach from "@intranet/view/anschrift/postfach/AnschriftPostfach.vue"
import {AnschriftViewModel} from "@intranet/view/anschrift/AnschriftViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {computed} from "vue"

const props = withDefaults(
    defineProps<{
        anschrift: Anschrift
        disabled?: boolean
        path: string
    }>(),
    {
        disabled: false,
    },
)

const emits = defineEmits<{
    validate: [path?: string]
}>()

const anschriftViewModel = computed<AnschriftViewModel>(() => {
    return new AnschriftViewModel(props.anschrift)
})

</script>

<template>
  <el-form-item :label="AnschriftViewModel.label.art">
    <el-radio-group v-model="props.anschrift.art" :disabled="disabled" name="art">
      <el-radio-button
              v-for="art in AnschriftArt"
              :key="art"
              :label="art"
              :data-cy="`anschrift-art-${art}`"
      >
        {{ capitalize(art) }}
      </el-radio-button>
    </el-radio-group>
  </el-form-item>
  <anschrift-person
          v-if="anschriftViewModel.art === AnschriftArt.PERSON"
          v-model:titel="anschriftViewModel.PERSON.titel"
          v-model:anrede="anschriftViewModel.PERSON.anrede"
          v-model:vorname="anschriftViewModel.PERSON.vorname"
          v-model:nachname="anschriftViewModel.PERSON.nachname"
          v-model:strasse="anschriftViewModel.PERSON.strasse"
          v-model:postleitzahl="anschriftViewModel.PERSON.postleitzahl"
          v-model:ort="anschriftViewModel.PERSON.ort"
          v-model:land="anschriftViewModel.PERSON.land"
          v-model:zusatz="anschriftViewModel.PERSON.zusatz"
          :label="AnschriftViewModel.label.PERSON"
          :path="props.path"
          @validate="emits('validate', path)"
  />
  <anschrift-unternehmen
          v-if="anschriftViewModel.art === AnschriftArt.UNTERNEHMEN"
          v-model:firma="anschriftViewModel.UNTERNEHMEN.firma"
          v-model:strasse="anschriftViewModel.UNTERNEHMEN.strasse"
          v-model:postleitzahl="anschriftViewModel.UNTERNEHMEN.postleitzahl"
          v-model:ort="anschriftViewModel.UNTERNEHMEN.ort"
          v-model:land="anschriftViewModel.UNTERNEHMEN.land"
          v-model:zusatz="anschriftViewModel.UNTERNEHMEN.zusatz"
          :label="AnschriftViewModel.label.UNTERNEHMEN"
          :path="props.path"
          @validate="emits('validate', path)"
  />
  <anschrift-postfach
          v-if="anschriftViewModel.art === AnschriftArt.POSTFACH"
          v-model:name="anschriftViewModel.POSTFACH.name"
          v-model:strasse="anschriftViewModel.POSTFACH.strasse"
          v-model:postleitzahl="anschriftViewModel.POSTFACH.postleitzahl"
          v-model:ort="anschriftViewModel.POSTFACH.ort"
          v-model:land="anschriftViewModel.POSTFACH.land"
          v-model:zusatz="anschriftViewModel.POSTFACH.zusatz"
          :label="AnschriftViewModel.label.POSTFACH"
          :path="props.path"
          @validate="emits('validate', path)"
  />
</template>

<style scoped lang="scss">

</style>