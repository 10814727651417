<script lang="ts" setup>
import DebitorStammdaten from "@intranet/view/debitor/DebitorStammdaten.vue"
import EditDialog from "@lib/view/editdialog/EditDialog.vue"
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {DebitorEditDialogController} from "@intranet/view/debitor/edit/DebitorEditDialogController"
import {ref} from "vue"

const props = withDefaults(
    defineProps<ComponentRendererProps & { controller: DebitorEditDialogController }>(),
    {
        onVanish: ComponentRenderer.onVanishDefault,
        __loader: ComponentRenderer.loaderDefault,
    },
)

const activeTab = ref(props.controller.tabs.first().name)

function save() {
    props.controller.save()
    props.done(props.controller.debitor)
}

</script>

<template>
  <edit-dialog
          v-model="activeTab"
          :controller="props.controller"
          :validator="props.controller.validator"
          @save="save"
  >
    <template #debitor-content>
      <debitor-stammdaten
              :id="props.controller.viewModel.id"
              v-model:controller="props.controller.debitorController.value"
      />
    </template>
  </edit-dialog>
</template>

<style lang="scss" scoped>

</style>