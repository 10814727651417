<script setup lang="ts">
import {Staat} from "@generated/de/lohn24/model/staat/Staat"
import {Id} from "@lib/common/model/Id"
import {Validateable, ValidateableInput} from "@lib/view"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Resource} from "@lib/model/Resource"
import {_props} from "@lib/common/_props"
import {IAnschriftPerson} from "@intranet/view/anschrift/person/IAnschriftPerson"
import {LabelProperty} from "@lib/types"
import {FontSize} from "@lib/common/Enums"
import {AnschriftPersonViewModel} from "@intranet/view/anschrift/person/AnschriftPersonViewModel"
import {Validator} from "@lib/common/validator/Validator"

const props = withDefaults(
    defineProps<
        IAnschriftPerson
        & LabelProperty<IAnschriftPerson>
        & { path: string }
        & { noMargin?: boolean, size?: FontSize }
    >(),
    {
        noMargin: false,
        size: FontSize.small,
    },
)

const emits = defineEmits<{
    "update:titel": [titel: string],
    "update:anrede": [anrede: string],
    "update:vorname": [vorname: string],
    "update:nachname": [nachname: string],
    "update:strasse": [strasse: string],
    "update:postleitzahl": [postleitzahl: string],
    "update:ort": [ort: string],
    "update:land": [land: Id<Staat>],
    "update:zusatz": [zusatz: string],
    "validate": [path?: string]
}>()

const errors = AnschriftPersonViewModel.error(Validator.getErrorTextFactory(props.path))

</script>

<template>
  <el-row :gutter="20">
    <el-col :span="13">
      <validateable-input
              :model-value="props.anrede"
              :error="errors[_props<IAnschriftPerson>().anrede]"
              :label="props.label[_props<IAnschriftPerson>().anrede]"
              :no-margin="props.noMargin"
              :size="props.size"
              @update:modelValue="emits(`update:anrede`, $event as string)"
              @validate="emits('validate', path)"
      />
    </el-col>
    <el-col :span="11">
      <validateable-input
              :model-value="props.titel"
              :error="errors[_props<IAnschriftPerson>().titel]"
              :label="props.label[_props<IAnschriftPerson>().titel]"
              :no-margin="props.noMargin"
              :size="props.size"
              label-width="85px"
              @update:modelValue="emits('update:titel', $event as string)"
              @validate="emits('validate', path)"
      />
    </el-col>
    <el-col :span="13">
      <validateable-input
              :model-value="props.vorname"
              :error="errors[_props<IAnschriftPerson>().vorname]"
              :label="props.label[_props<IAnschriftPerson>().vorname]"
              :no-margin="props.noMargin"
              :size="props.size"
              @update:modelValue="emits(`update:vorname`, $event as string)"
              @validate="emits('validate', path)"
      />
    </el-col>
    <el-col :span="11">
      <validateable-input
              :model-value="props.nachname"
              :error="errors[_props<IAnschriftPerson>().nachname]"
              :label="props.label[_props<IAnschriftPerson>().nachname]"
              :no-margin="props.noMargin"
              :size="props.size"
              label-width="85px"
              @update:modelValue="emits(`update:nachname`, $event as string)"
              @validate="emits('validate', path)"
      />
    </el-col>
  </el-row>
  <validateable-input
          :model-value="props.strasse"
          :error="errors[_props<IAnschriftPerson>().strasse]"
          :label="props.label[_props<IAnschriftPerson>().strasse]"
          :no-margin="props.noMargin"
          :size="props.size"
          @update:modelValue="emits('update:strasse', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.postleitzahl"
          :error="errors[_props<IAnschriftPerson>().postleitzahl]"
          :label="props.label[_props<IAnschriftPerson>().postleitzahl]"
          :no-margin="props.noMargin"
          :size="props.size"
          @update:modelValue="emits('update:postleitzahl', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable-input
          :model-value="props.ort"
          :error="errors[_props<IAnschriftPerson>().ort]"
          :label="props.label[_props<IAnschriftPerson>().ort]"
          :no-margin="props.noMargin"
          :size="props.size"
          @update:modelValue="emits('update:ort', $event as string)"
          @validate="emits('validate', path)"
  />
  <validateable
          :error="errors[_props<IAnschriftPerson>().land]"
          :label="props.label[_props<IAnschriftPerson>().land]"
          :no-margin="props.noMargin"
          :size="props.size"
  >
    <repository-options
            :model-value="props.land"
            @update:modelValue="emits('update:land', $event as Id<Staat>)"
            :repository="Resource.land"
            @change="emits('validate', undefined)"
    />
  </validateable>
  <validateable-input
          :model-value="props.zusatz"
          :error="errors[_props<IAnschriftPerson>().zusatz]"
          :label="props.label[_props<IAnschriftPerson>().zusatz]"
          :no-margin="props.noMargin"
          :size="props.size"
          @update:modelValue="emits('update:zusatz', $event as string)"
          @validate="emits('validate', path)"
  />
</template>

<style scoped lang="scss">

</style>